import { useEffect, useState } from 'react';
import { List } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import {
	LuCopyCheck,
	LuNewspaper,
	LuBellRing,
	LuShare2,
	LuSendHorizontal,
	LuMegaphone,
	LuPartyPopper,
	LuCalendarCheck,
	LuHandshake,
	LuUserRoundPlus,
	LuMailQuestion,
} from 'react-icons/lu';
import { NOTIFICATIONS } from '../../_shared/constants';
import {
	ListItemPageStyles,
	ListItemPageStylesBlue,
	ListItemStyles,
	ListItemStylesBlue,
} from '../webNotificationsStyles.js';
import { getFilteredNotifications } from 'src/_shared/services/utils';
dayjs.extend(relativeTime);

function WebNotificationDropdownList({
	userNotifications,
	nextTokenNotifications,
	currentUser,
	dropDown = true,
	lastNotificationCheck,
}) {
	const [avatarData, setAvatarData] = useState({});
	const [allNotifications, setAllNotifications] = useState([]);
	const [filteredNotifications, setFilteredNotifications] = useState([]);

	useEffect(() => {
		const checkAndLoadMore = async () => {
			if (!userNotifications) return;
			const allItems = userNotifications || [];
			setAllNotifications(allItems);

			const filtered = getFilteredNotifications(allItems);
			setFilteredNotifications(filtered);
		};

		checkAndLoadMore();
	}, [userNotifications]);

	useEffect(() => {
		const loadAvatars = async () => {
			if (!filteredNotifications.length) return;
			const newAvatars = {};
			for (const notification of filteredNotifications) {
				if (avatarData[notification.id]) continue;

				try {
					const avatar = await renderAvatar(notification);
					if (avatar) {
						newAvatars[notification.id] = avatar;
					}
				} catch (error) {
					console.error('Error loading avatar:', error);
				}
			}

			if (Object.keys(newAvatars).length > 0) {
				setAvatarData((prev) => ({ ...prev, ...newAvatars }));
			}
		};

		loadAvatars();
	}, [filteredNotifications]);

	const renderAvatar = async (notification) => {
		const { type, user } = notification;

		switch (type) {
			case NOTIFICATIONS.REFERRAL_CREATED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
						}}
					>
						<LuUserRoundPlus
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.REFERRAL_REQUESTED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(109.2deg, hsla(305, 91%, 83%, 1) 6.5%, hsla(238, 95%, 76%, 1) 97.1%)',
						}}
					>
						<LuMailQuestion
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.REFERRAL_ACCEPTED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'radial-gradient(circle at 12.3% 19.3%, hsla(238, 65%, 59%, 1) 0%, hsla(199, 93%, 67%, 1) 100.2%)',
						}}
					>
						<LuPartyPopper
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.JOB_CREATED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(0deg, hsla(195, 85%, 50%, 1) 0%, hsla(156, 93%, 55%, 1) 100%)',
						}}
					>
						<LuBellRing
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.REFERRAL_HIRED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(90deg, hsla(339, 100%, 55%, 1) 0%, hsla(197, 100%, 64%, 1) 100%)',
						}}
					>
						<LuHandshake
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.CONTACTS_IMPORTED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(to top, hsla(200, 81%, 61%, 1) 0%, hsla(227, 55%, 64%, 1) 100%)',
						}}
					>
						<LuUserRoundPlus
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.REFERRAL_MATCH: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(0deg, hsla(195, 85%, 50%, 1) 0%, hsla(156, 93%, 55%, 1) 100%)',
						}}
					>
						<LuCopyCheck
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.JOB_SUMMARY_EMAIL: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(90deg, hsla(12, 100%, 77%, 1) 0%, hsla(346, 100%, 70%, 1) 55%, hsla(352, 100%, 80%, 1) 100%)',
						}}
					>
						<LuNewspaper
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.EMP_MESSAGE_CENTER: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(90deg, hsla(12, 100%, 77%, 1) 0%, hsla(346, 100%, 70%, 1) 55%, hsla(352, 100%, 80%, 1) 100%)',
						}}
					>
						<LuMegaphone
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.SOCIAL_MEDIA_SHARE: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(to top, hsla(200, 81%, 61%, 1) 0%, hsla(227, 55%, 64%, 1) 100%)',
						}}
					>
						<LuShare2 size="1.7rem" style={{ color: 'white', opacity: 0.9 }} />
					</div>
				);
			}
			case NOTIFICATIONS.REFERRAL_SENT: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(120deg, hsla(292, 94%, 78%, 1) 0%, hsla(351, 89%, 66%, 1) 100%)',
						}}
					>
						<LuSendHorizontal
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.GENERAL_REFERRAL: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(135deg, hsla(147, 80%, 71%, 1) 10%, hsla(210, 87%, 50%, 1) 100%)',
						}}
					>
						<LuSendHorizontal
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.POINTS_REFERRAL_ACCEPTED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'radial-gradient(circle at 12.3% 19.3%, hsla(238, 65%, 59%, 1) 0%, hsla(199, 93%, 67%, 1) 100.2%)',
						}}
					>
						<LuPartyPopper
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.POINTS_REFERRAL_INTERVIEWING: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(to right, hsla(339, 85%, 69%, 1), hsla(16, 100%, 72%, 1))',
						}}
					>
						<LuCalendarCheck
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			case NOTIFICATIONS.POINTS_REFERRAL_HIRED: {
				return (
					<div
						className="notification-icon"
						style={{
							borderRadius: '8px',
							backgroundImage:
								'linear-gradient(to right, hsla(240, 84%, 67%, 1), hsla(271, 91%, 65%, 1), hsla(330, 81%, 61%, 1))',
						}}
					>
						<LuHandshake
							size="1.7rem"
							style={{ color: 'white', opacity: 0.9 }}
						/>
					</div>
				);
			}
			default: {
				return null;
			}
		}
	};

	const defineNotificationStyles = (item) => {
		if (
			(!lastNotificationCheck || item.dateCreated > lastNotificationCheck) &&
			dropDown
		) {
			return ListItemStylesBlue;
		}

		if (item.dateCreated < lastNotificationCheck && dropDown) {
			return ListItemStyles;
		}

		if (
			(!lastNotificationCheck || item.dateCreated > lastNotificationCheck) &&
			dropDown
		) {
			return ListItemPageStylesBlue;
		}

		return ListItemPageStyles;
	};

	return (
		<>
			{filteredNotifications && filteredNotifications.length > 0 ? (
				<List
					className={'notification-list-wrap'}
					dataSource={filteredNotifications.slice(0, 5)}
					pagination={false}
					renderItem={(item) => (
						<List.Item>
							<>
								{avatarData[item.id] || (
									<div className="notification-icon"></div>
								)}
							</>
							<>
								{item.type === NOTIFICATIONS.REFERRAL_REQUESTED ? (
									<div className="notification-text">
										<Link to="/mycontacts">
											<span className="notification-text-blue">
												{item.requestingUser.firstName}{' '}
												{item.requestingUser.lastName}
											</span>
										</Link>{' '}
										has requested a referral for{' '}
										<Link to={`/browsejobs/${get(item, 'jobId')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
											</span>
										</Link>
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.SOCIAL_MEDIA_SHARE ? (
									<div className="notification-text">
										You've earned{' '}
										<Link to="/points">
											<strong>
												{get(item, 'matches')} point
												{item?.matches > 1 ? 's' : ''}
											</strong>
										</Link>{' '}
										for sharing the{' '}
										<Link to={`/browsejobs/${get(item, 'jobId')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
											</span>
										</Link>
										{' job'}
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.GENERAL_REFERRAL &&
								  item.referral ? (
									<div className="notification-text">
										You've earned{' '}
										<Link to="/points">
											<strong>
												{get(item, 'matches')} point
												{item?.matches > 1 ? 's' : ''}
											</strong>
										</Link>{' '}
										{'for sending a general referral for '}
										<Link to={`/browsejobs/${get(item, 'jobId', '')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
											</span>
										</Link>
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.REFERRAL_SENT &&
								  item.referral ? (
									<div className="notification-text">
										You've earned{' '}
										<Link to="/points">
											<strong>
												{get(item, 'matches')} point
												{item?.matches > 1 ? 's' : ''}
											</strong>
										</Link>{' '}
										{'for sending a referral for '}
										<Link to={`/browsejobs/${get(item, 'jobId', '')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
											</span>
										</Link>
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.POINTS_REFERRAL_ACCEPTED &&
								  item.referral ? (
									<div className="notification-text">
										<Link to={`/mycontacts/${get(item, 'contactId', '')}`}>
											<span className="notification-text-blue">
												{item?.contact?.firstName} {item?.contact?.lastName}
											</span>
										</Link>{' '}
										{'has accepted your referral for '}
										<Link to={`/browsejobs/${get(item, 'jobId', '')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
											</span>
										</Link>
										{'. '}
										You've earned{' '}
										<Link to="/points">
											<strong>
												{item?.matches} point{item?.matches > 1 ? 's' : ''}
											</strong>
										</Link>
										{'!'}
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.POINTS_REFERRAL_INTERVIEWING &&
								  item.referral ? (
									<div className="notification-text">
										<Link to={`/mycontacts/${get(item, 'contactId', '')}`}>
											{item.referral && (
												<span className="notification-text-blue">
													{item?.contact?.firstName ||
														item?.referral?.contact?.firstName}{' '}
													{item?.contact?.lastName ||
														item?.referral?.contact?.lastName}
												</span>
											)}
										</Link>{' '}
										has had their status progress for{' '}
										<Link to={`/browsejobs/${get(item, 'jobId', '')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
												{'. '}
											</span>
										</Link>
										You've earned{' '}
										<Link to="/points">
											<strong>
												{item?.matches} point{item?.matches > 1 ? 's' : ''}
											</strong>
										</Link>
										{'!'}
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.JOB_CREATED ? (
									<div className="notification-text">
										<strong>
											{item.job.subCompany === null
												? currentUser?.company?.name
												: item.job.subCompany.name}
										</strong>{' '}
										has added a new job{' '}
										<Link to={`/browsejobs/${get(item, 'jobId', '')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
											</span>
										</Link>
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : item.type === NOTIFICATIONS.POINTS_REFERRAL_HIRED &&
								  item.referral ? (
									<div className="notification-text">
										<Link to={`/mycontacts/${get(item, 'contactId', '')}`}>
											{item.referral && (
												<span className="notification-text-blue">
													{item?.contact?.firstName ||
														item?.referral?.contact?.firstName}{' '}
													{item?.contact?.lastName ||
														item?.referral?.contact?.lastName}
												</span>
											)}
										</Link>{' '}
										has been hired for{' '}
										<Link to={`/browsejobs/${get(item, 'jobId', '')}`}>
											<span className="notification-text-blue">
												{get(item, 'job.title', '')}
												{'. '}
											</span>
										</Link>
										You've earned{' '}
										<Link to="/points">
											<strong>
												{item?.matches} point{item?.matches > 1 ? 's' : ''}
											</strong>
										</Link>
										{'!'}
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								) : (
									<div className="notification-text">
										<span className="notification-text-blue">
											{item.message}
										</span>
										<span className="notification-time">
											{dayjs(`${item.dateCreated}`).fromNow()}
										</span>
									</div>
								)}
							</>
						</List.Item>
					)}
				/>
			) : (
				<p> No Notifications </p>
			)}
			<div className="notification-view-more">
				<Link to="/notifications">View More</Link>
			</div>
		</>
	);
}

export default WebNotificationDropdownList;
