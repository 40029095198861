// src/bonus-report-container.js
import { connect } from 'react-redux';
import { compose } from '../_shared/services/utils.js';
import BonusReportComponent from './BonusReportComponent';
import { withApollo } from 'react-apollo';
import { 
  fetchBonuses, 
  updateReferral, 
  createBonus,
  setFilteredStatuses,
  setFilteredDates,
  setFilteredUserGroups,
  setFilteredReferralId,
  setSearchQuery,
  setExportData
} from 'src/_shared/referral/referralActions';
import { dashboardActions, manageJobsActions } from 'src/actions.js';

const mapStateToProps = (state, ownProps) => {
  const { currentUser } = state.user;
  const { allMultiLingualData } = state.dashboard;
  const {
    bonuses ,
    bonusCounts,
    totalBonusCount,
    loading,
    isCardDataSet,
    filteredStatuses,
    filteredDates,
    filteredUserGroups,
    filteredReferralId,
    searchQueryBonuses,
    exportTableData
  } = state.referrals;

  return {
    currentUser,
    allMultiLingualData,
    bonuses,
    bonusCounts,
    totalBonusCount,
    loading,
    isCardDataSet,
    filteredStatuses,
    filteredDates,
    filteredUserGroups,
    filteredReferralId,
    searchQueryBonuses,
    exportTableData,
	...ownProps
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchBonuses: (parameters) => dispatch(fetchBonuses(parameters)),
    onUpdateReferral: (input) => dispatch(updateReferral(input)),
    onCreateBonus: (input) => dispatch(createBonus(input)),
    setFilteredStatuses: (statuses) => dispatch(setFilteredStatuses(statuses)),
    setFilteredDates: (dates) => dispatch(setFilteredDates(dates)),
    setFilteredUserGroups: (userGroups) => dispatch(setFilteredUserGroups(userGroups)),
    setFilteredReferralId: (referralId) => dispatch(setFilteredReferralId(referralId)),
    setSearchQuery: (query) => dispatch(setSearchQuery(query)),
    setExportData: (data) => dispatch(setExportData(data)),
    
    // Legacy actions
    setCurrentCurrencyRate: (currencyRate) => 
      dispatch(manageJobsActions.setCurrentCurrencyRate(currencyRate)),
    setCurrentCurrencySymbol: (currencySymbol) => 
      dispatch(manageJobsActions.setCurrentCurrencySymbol(currencySymbol)),
    setMultiLingualData: (data) => 
      dispatch(dashboardActions.createSetMultiLingualData(data)),
  };
};

export const BonusReportContainer = compose(
  withApollo,
  connect(mapStateToProps, mapDispatchToProps)
)(BonusReportComponent);
