import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Input, message } from 'antd';
import gql from 'graphql-tag';
import get from 'lodash/get';
import { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import ReactDOM from 'react-dom';
import ProgressButton from 'react-progress-button';
import useWindowSize from 'react-use/lib/useWindowSize';
import { configMode } from 'src/_shared/api/';
import { queryReferralsByJobIdIndex } from 'src/_shared/api/graphql/custom/referrals/';
import FormElements from 'src/form-builder/FormElements.jsx';
import {
	filterPhoneNumber,
	getEnvironment,
	lambda,
	lowerCase,
	ml,
	parse,
	sanitize,
} from '../../services/utils';
import { emailPattern, phonePattern } from 'src/_shared/constants/regex';
import ReferralFormAddContactFields from './ReferralFormAddContactFieldsComponent.jsx';
import ReferralLanguageSelector from 'src/_shared/components/ReferralLanguageSelectorComponent';
import ShareSendReferralForm from 'src/_shared/components/ShareSendReferralFormComponent';

// This component is used to make referrals
const inputs = {};
function ReferralComplianceForm(props) {
	const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
	const [referralType, setReferralType] = useState('email');
	const [buttonState, setButtonState] = useState('');
	const [selectedContact, setSelectedContact] = useState(props.contact);
	const [showConfetti, setShowConfetti] = useState(false);
	const [errors, setErrors] = useState('');
	const { width, height } = useWindowSize();
	const [referralLanguage, setReferralLanguage] = useState(
		props?.currentUser?.languageCode || 'US'
	);
	const {
		allMultiLingualData,
		contacts,
		currentUser,
		job,
		handleCancel,
		onCreateReferral,
		onCreateWebNotification,
		companyData,
		client,
		form,
		referralQuestions,
		handleNotificationCompleted,
		handleViewContact,
		notification,
		onUpdateJob,
		onCreateUserJobShare,
		onUpdateUserJobShare,
	} = props;

	let hideDepartmentShareLink = false;
	if (companyData.hideShareLinkForDepartment) {
		const jsonData = parse(companyData.hideShareLinkForDepartment);
		hideDepartmentShareLink = jsonData.some(function (value) {
			return value.id === job.departmentId;
		});
	}
	const pointsSettings = get(companyData, 'pointsSettings');

	const { getFieldDecorator } = form;
	const { TextArea } = Input;
	const FormItem = Form.Item;

	// Handler for email/phone number referral method toggler
	const changeReferralType = (referralType) => {
		setReferralType(referralType);
	};

	const handleSelectContact = async (contact) => {
		// Make sure job isn't undefined
		if (!job && !job.id) {
			return;
		}

		// Asyncronously validate to see if this contact has already been referred,
		// and show error if it has, else set to state and change mode
		try {
			const alreadyReferred = await client
				.query({
					query: gql(queryReferralsByJobIdIndex),
					variables: { jobId: job.id },
					fetchPolicy: 'network-only',
				})
				.then((response) =>
					response.data.queryReferralsByJobIdIndex.items.some(
						(referral) =>
							(referral.contact &&
								referral.contact.emailAddress &&
								referral.contact.emailAddress === contact.emailAddress) ||
							(referral.contact &&
								referral.contact.phoneNumber &&
								filterPhoneNumber(referral.contact.phoneNumber) ===
									contact.phoneNumber)
					)
				);

			if (alreadyReferred) {
				message.error('You Already Referred That Person', 5);
			} else {
				// Set selected contact, and change JSX mode to display selectedContact
				setSelectedContact(contact);
				// When user selects a contact, default back whatever referralType is available.
				if (contact.emailAddress) {
					setReferralType('email');
				} else if (contact.phoneNumber) {
					setReferralType('text');
				}
			}
		} catch (error) {
			console.log(
				'validator error for alreadyReferred in handleSelectContact',
				error
			);
		}
	};

	const handleClearSelected = () => {
		setSelectedContact(null);
		form.resetFields();
	};

	// START FORM BUILDER----------------------
	const data_items = referralQuestions;
	const items = data_items.map((item) => {
		if (!item) return null;
		switch (item.element) {
			case 'TextInput':
			case 'NumberInput':
			case 'TextArea':
			case 'Dropdown':
			case 'DatePicker':
			case 'RadioButtons':
			case 'Rating':
			case 'Tags':
			case 'Range': {
				return getInputElement(item);
			}

			default: {
				return getSimpleElement(item);
			}
		}
	});

	function _getItemValue(item, ref) {
		let $item = {
			element: item.element,
			value: '',
			text: '',
		};
		switch (item.element) {
			case 'Rating': {
				$item.value = ref.inputField.current.state.rating;

				break;
			}

			case 'Tags': {
				$item.value = ref.inputField.current.state.value;

				break;
			}

			case 'DatePicker': {
				$item.value = ref.state.value;

				break;
			}

			case 'Camera': {
				$item.value = ref.state.img
					? ref.state.img.replace('data:image/png;base64,', '')
					: '';

				break;
			}

			default: {
				if (ref && ref.inputField) {
					$item = ReactDOM.findDOMNode(ref.inputField.current);
					if (typeof $item.value === 'string') {
						$item.value = $item.value.trim();
						if (item.element === 'Dropdown') {
							$item.text = $item.selectedOptions[0].innerText;
						}
					}
				}
			}
		}

		return $item;
	}

	function _collect(item) {
		const errors = [];
		const itemData = { name: item.field_name };
		const ref = inputs[item.field_name];
		if (item.element === 'Checkboxes' || item.element === 'RadioButtons') {
			const checked_options = [];
			for (const option of item.options) {
				const $option = ReactDOM.findDOMNode(
					ref.options[`child_ref_${option.key}`]
				);
				if ($option.checked) {
					checked_options.push(option.key);
				}
			}

			itemData.value = checked_options;
		} else {
			if (!ref) return null;
			itemData.value = _getItemValue(item, ref).value;

			itemData.question = item.label;
			itemData.text =
				_getItemValue(item, ref).text === undefined
					? ''
					: _getItemValue(item, ref).text;
			if (itemData.value === '' && itemData.text === '') {
				errors.push(`${item.label} is required.`);
			}
		}

		const object = {
			itemData,
			errors,
		};
		return object;
	}

	function _collectFormData(data) {
		const formData = [];
		let errors = '';
		for (const item of data) {
			const item_data =
				_collect(item) === null ? null : _collect(item).itemData;
			errors += _collect(item) === null ? '' : _collect(item).errors;
			if (item_data) {
				formData.push(item_data);
			}
		}

		const object = {
			formData,
			errors,
		};
		return object;
	}

	function getInputElement(item) {
		const Input = FormElements[item.element];

		return (
			<div>
				<Input
					ref={(c) => (inputs[item.field_name] = c)}
					key={`form_${item.id}`}
					mutable
					data={item}
					errors={errors}
					read_only={props.read_only}
				/>
			</div>
		);
	}

	function getSimpleElement(item) {
		const Element = FormElements[item.element];
		return <Element key={`form_${item.id}`} mutable data={item} />;
	}

	// ----END FORM BUILDER---------------------

	const handleSubmit = async (e) => {
		e.preventDefault();
		const handleError = get(props, 'handleError');
		// Check to ensure values are not undefined before proceeding
		if (!currentUser || !currentUser.id || !job || !job.id) {
			return;
		}

		try {
			const { errors, values } = await new Promise((resolve) => {
				form.validateFields((errors, values) => {
					resolve({ errors, values });
				});
			});

			const questionsData = _collectFormData(props.referralQuestions).formData;
			questionsData.map((item) => {
				const value = sanitize(item.value);
				item.value = value;
			});
			const quesErrors = _collectFormData(props.referralQuestions).errors;
			if (errors || quesErrors.length > 0) {
				setButtonState('error');
				setErrors(quesErrors);
				setTimeout(() => {
					setButtonState('');
				}, 1500);
				return;
			}

			// If user already submitted, return if they click again. Prevents them from double clicking submit
			if (isSubmitDisabled) {
				return;
			}

			// If no errors
			setButtonState('loading');
			setIsSubmitDisabled(true);

			const d = new Date();
			let { firstName } = values;
			firstName &&= sanitize(firstName);
			let { lastName } = values;
			lastName &&= sanitize(lastName);
			let { note } = values;
			note &&= sanitize(note);
			let message_ = values.message;
			message_ &&= sanitize(message);
			let { emailAddress } = values;
			emailAddress &&= sanitize(emailAddress);
			let { phoneNumber } = values;
			phoneNumber &&= sanitize(phoneNumber);

			// If submitting referral for existing contact do these things--------------------------------------------
			if (selectedContact) {
				const referral = {
					companyId: currentUser.companyId,
					contactId: selectedContact.id,
					referralType,
					userId: currentUser.id,
					jobId: job.id,
					languageCode:
						referralLanguage || get(currentUser, 'languageCode', 'US'),
					status: 'referred',
					referralSource: 'direct',
					referralDevice: 'web',
					questionsData: JSON.stringify(questionsData),
				};
				if (get(notification, 'type') === 'referralRequested')
					referral.referralSource = 'network';
				if (values.note) referral.note = note;
				if (values.message) referral.message = message_;
				const createdReferral = await onCreateReferral(referral).then(
					(response) => response.data.createReferral
				);
				if (createdReferral && handleNotificationCompleted)
					handleNotificationCompleted();

				// -------------------------- START ENABLE PROSPECT CREATION---------------------------

				if (
					get(createdReferral, 'job.externalSource') === 'Greenhouse' &&
					get(createdReferral, 'contact.emailAddress') &&
					get(currentUser, 'company.enableProspectCreation')
				) {
					const prospect = {
						firstName: createdReferral.contact.firstName,
						lastName: createdReferral.contact.lastName,
						emailId: createdReferral.contact.emailAddress,
						externalJobId: createdReferral.job.externalJobId,
						companyId: currentUser.companyId,
						referralEmailId: createdReferral.user.emailAddress,
						custom_fields: [],
					};

					prospect.configMode = configMode === 'DEV' ? 'dev' : 'prod';
					const endpoint = 'GreenhouseCreateProspectDev';
					await lambda({ endpoint, variables: prospect });
				}

				// --------------------------END PROSPECT CREATION-----------------------------

				// Show success
				setButtonState('success');
				if (
					referralSentPoints &&
					referralSentPoints !== 0 &&
					referralSentPoints !== '0' &&
					referralSentPoints !== null
				) {
					message.success(`You earned ${referralSentPoints} points`, 5);
					setShowConfetti(true);
				} else {
					message.success('Your request is submitted.', 5);
				}

				await new Promise((resolve) => {
					setTimeout(() => resolve(), 2000);
				});

				// Close modal
				handleCancel();

				// If fail, reset submit button and allow user to try again
				setButtonState('');
				setIsSubmitDisabled(false);
				// Submitting referral for new contact-------------------------
			} else {
				let isAlreadyReferredToJob = false;
				try {
					isAlreadyReferredToJob = await client
						.query({
							query: gql(queryReferralsByJobIdIndex),
							variables: { jobId: job.id },
							fetchPolicy: 'network-only',
						})
						.then((response) =>
							response.data.queryReferralsByJobIdIndex.items.some(
								(referral) =>
									(referral.contact &&
										referral.contact.emailAddress &&
										referral.contact.emailAddress === emailAddress) ||
									(referral.contact &&
										referral.contact.phoneNumber &&
										filterPhoneNumber(referral.contact.phoneNumber) ===
											phoneNumber)
							)
						);
				} catch (error) {
					console.log('validator error for alreadyReferredToJob', error);
				}

				if (isAlreadyReferredToJob) {
					message.error(
						'This person has already been referred for this job.',
						5
					);
					setButtonState('');
					setIsSubmitDisabled(false);
					return;
				}

				form.validateFields(async (error, values) => {
					let email = null;
					let phone = null;
					let referralDevice = 'web';
					let referralType = 'email';

					if (values.emailAddress && emailPattern.test(emailAddress)) {
						email = emailAddress;
						referralDevice = 'web';
						referralType = 'email';
					}

					if (
						values.phoneNumber &&
						phonePattern.test(
							filterPhoneNumber(get(values, 'phoneNumber', false))
						)
					) {
						phone = filterPhoneNumber(phoneNumber);
						referralDevice = 'mobile';
						referralType = 'text';
					}

					const location = parse(
						get(props, 'job.job.location', get(props, 'job.location'))
					);

					const webNotification = {
						input: {
							companyId: get(currentUser, 'companyId'),
							dateCreated: new Date().toISOString(),
							jobId: get(job, 'id'),
							status: 'referred',
							referralDevice,
							referralType,
							requestingUserId: get(currentUser, 'id'),
							type: 'gdprReferralCreated',
							userId: get(currentUser, 'id'),
							questionsData: JSON.stringify(questionsData),
						},
					};

					if (get(props, 'notification.type') === 'referralRequested')
						webNotification.input.referralSource = 'network';

					const wNotification = await onCreateWebNotification(webNotification);
					const referral = {
						companyId: currentUser.companyId,
						referredBy: currentUser.id,
						jobId: job.id,
						referralDevice,
						referrerFirstName: currentUser.firstName,
						referrerLastName: currentUser.lastName,
						firstName,
						lastName,
						brandColor: currentUser.company.brandColor,
						brandLogo: currentUser.company.logo,
						note: values.note ? note : null,
						company: currentUser.company.name,
						title: job.title,
						location: location || '{}',
						languageCode:
							referralLanguage || get(currentUser, 'languageCode', 'US'),
						avatar: currentUser.avatar,
						whiteLabel: get(currentUser, 'company.whiteLabel', false),
						host: get(currentUser, 'company.host', 'app.erinapp.com'),
						senderEmailAddress: get(
							currentUser,
							'company.senderEmailAddress',
							'ERIN <noreply@erinapp.com>'
						),
						webNotificationId: get(wNotification, 'id'),
						subCompanyId: get(currentUser, 'subCompanyId'),
						subCompanyName: get(currentUser, 'subCompany.name'),
						subCompanyLogo: get(currentUser, 'subCompany.logo'),
						ignoreSubcompany: get(currentUser, 'company.ignoreSubcompany'),
					};
					if (phone) {
						referral.phoneNumber = filterPhoneNumber(phone);
						referral.referralType = 'text';
					}

					if (email) {
						referral.emailAddress = lowerCase(email);
						referral.referralType = 'email';
					}

					if (get(props, 'notification.type') === 'referralRequested')
						referral.referralSource = 'network';

					if (error) {
						handleError();
					} else {
						try {
							let endpoint = '';
							endpoint =
								getEnvironment() === 'dev'
									? 'gdpr-referral-created-dev-app'
									: 'gdpr-referral-created-prod-app';
							await lambda({ endpoint, variables: { referral } });
							setButtonState('success');
						} catch (lambdaError) {
							console.log('lambdaError', lambdaError);
							setButtonState('error');
						}
					}

					// Show success
					setButtonState('success');
					if (
						referralSentPoints &&
						referralSentPoints !== 0 &&
						referralSentPoints !== '0' &&
						referralSentPoints !== null
					) {
						message.success(`You earned ${referralSentPoints} points`, 5);
						setShowConfetti(true);
					} else {
						message.success('Your request is submitted.', 5);
					}

					await new Promise((resolve) => {
						setTimeout(() => resolve(), 2000);
					});

					// Close modal
					handleCancel();

					// If fail, reset button and allow user to try again
					setButtonState('');
					setIsSubmitDisabled(false);
				});
			}
		} catch (error) {
			console.log(error);
		}
	};

	let referralSentPoints = null;
	if (pointsSettings !== null && pointsSettings !== undefined) {
		const pointsSettingsData = JSON.parse(pointsSettings);
		if (pointsSettingsData.enabled) {
			referralSentPoints = get(pointsSettingsData, 'referralSent');
		}
	}

	const shouldDisplayForm = () => {
		return (
			!companyData.disableShareLink &&
			job.status === 'open' &&
			currentUser.role !== 'extendedUser' &&
			!hideDepartmentShareLink &&
			(!companyData.hideShareLinkNoPublicUrl || job.publicLink)
		);
	};

	const ShareSendForm = () => {
		return (
			<ShareSendReferralForm
				allMultiLingualData={allMultiLingualData}
				client={client}
				currentUser={currentUser}
				jobId={job?.id}
				job={job}
				languageCode={referralLanguage}
				onUpdateJob={onUpdateJob}
				onCreateUserJobShare={onCreateUserJobShare}
				onUpdateUserJobShare={onUpdateUserJobShare}
			/>
		);
	};

	return (
		<Form onSubmit={handleSubmit}>
			{showConfetti && (
				<div
					style={{
						position: 'absolute',
						top: '5px',
						width: '10%',
						left: '45%',
					}}
				>
					<ConfettiExplosion
						style={{
							force: 1,
							duration: 4000,
							particleCount: 400,
							height,
							width,
							margin: 'auto',
						}}
					/>
				</div>
			)}
			<ReferralFormAddContactFields
				handleViewContact={handleViewContact}
				contact={props.contact}
				form={props.form}
				selectedContact={selectedContact}
				handleSelectContact={handleSelectContact}
				handleClearSelected={handleClearSelected}
				referralType={referralType}
				changeReferralType={changeReferralType}
				contacts={contacts}
				currentUser={currentUser}
				allMultiLingualData={allMultiLingualData}
				job={job}
			/>
			<div className="custom-form-group">
				<label className="custom-label">
					{ml(
						'Include a message to your contact',
						currentUser,
						allMultiLingualData
					)}
					<span> {ml('(optional)', currentUser, allMultiLingualData)}</span>
				</label>
				<FormItem>
					{getFieldDecorator(
						'note',
						{}
					)(
						<TextArea
							className="custom-input"
							placeholder={ml(
								'Personalize the message to your referral',
								currentUser,
								allMultiLingualData
							)}
							rows={4}
						/>
					)}
				</FormItem>
			</div>
			<>{items}</>
			<div className="modal-footer-btn">
				<ProgressButton controlled durationSuccess={3000} state={buttonState}>
					{ml('Submit Referral', currentUser, allMultiLingualData)}
				</ProgressButton>
			</div>
			<ReferralLanguageSelector
				allMultiLingualData={allMultiLingualData}
				currentUser={currentUser}
				setReferralLanguage={setReferralLanguage}
			/>
			{shouldDisplayForm() && <ShareSendForm />}
		</Form>
	);
}

export default Form.create()(ReferralComplianceForm);
