import { Button } from 'antd';
import get from 'lodash/get';
import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { formatDate, ml, mapReferralStatus } from '../../services/utils';
import { queryReferralAuditLogs } from 'src/_shared/api/graphql/custom/referrals/';
import gql from 'graphql-tag';


function ReferralLogsModal(props) {
	const { currentUser, referral, handleReferralCancel, allMultiLingualData, client } = props;
	const languageCode = get(currentUser, 'languageCode', 'US');

	const history = useHistory();
	const navigateTo = (URL) => {
		history.push(URL);
	};

	const [referralLogResults, setReferralLogResults] = useState();

	useEffect(() => {
		const fetchReferralLogs = async () => {
			try {
				const { data } = await client.query({
					query: gql(queryReferralAuditLogs),
					variables: {
						referralId: get(referral, 'id'),
					},
					fetchPolicy: 'network-only',
				});
				const result = data?.queryAuditLogsByReferralId?.items ?? [];
				setReferralLogResults(result);
			} catch (error) {
				console.log(error);
			}
		};

		fetchReferralLogs();
	}, []);

	const formattedDate = (date) => {
		return date ? formatDate(
			date,
			languageCode,
			currentUser?.dateFormat
		) : '';
	}

	const capitalizeFirstLetter = (string) => {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	const fullName = (contact) => {
		return `${contact.firstName} ${contact.lastName}`;
	}

	const referralLogEntry = (logEntry) => {
		const {
			newValue,
			eventTime,
			userEmail,
			fieldName,
			note
		} = logEntry;

		const statusText = fieldName === 'customStatus' ? 'stage' : 'status';
		const date = formattedDate(eventTime);
		const mappedStatus = ml(
			fieldName === 'customStatus' ? newValue : mapReferralStatus(newValue, currentUser.company),
			currentUser,
			allMultiLingualData
		);

		let message = '';
		if (userEmail) {
			message = `${userEmail} updated ${statusText} to "${mappedStatus}" on ${date}`;
		} else {
			message = `Referral ${statusText} updated to "${mappedStatus}" on ${date}`;
		}

		if (note && newValue === 'ineligible') {
			message += ` with note "${note}"`
		}

		return message;
	}

	const renderLogs = () => {
		if (!referralLogResults) {
			return "Loading...";
		}

		// order by eventTime desc
		const logs = referralLogResults
			.map((logEntry, index) => (
				<p key={index}>
					{referralLogEntry(logEntry)}
				</p>
			));
		
		// add default log entry for referral creation
		logs.push(
			<p key={logs.length}>
				{`Referral created on ${formattedDate(get(referral, 'referralDate'))}`}
			</p>
		);

		if (logs.length >= 50) {
			logs.unshift(
				<p key={logs.length+1}>
					{`(showing 50 most recent log entries)`}
				</p>
			);
		}

		return logs;
	}

	return (
		<>
			<ul className="label-data">
				<li>
					<label>
						{ml('Job', currentUser, allMultiLingualData)}:
					</label>
					<a
						href={`/jobs/${get(referral, 'job.id')}`}
						onClick={(event) => {
							event.preventDefault();
							navigateTo(`/jobs/${get(referral, 'job.id')}`);
						}}
					>
						{get(referral, 'job.title')}
					</a>
				</li>
			</ul>

			<ul className="label-data">
				<li>
					<label>
						{ml('Referred Candidate', currentUser, allMultiLingualData)}:
					</label>
					{fullName(get(referral, 'contact'))}
				</li>
			</ul>

			<ul className="label-data">
				<li>
					<label>
						{ml('Referred By', currentUser, allMultiLingualData)}:
					</label>
					<a
						href={`/employees/${get(referral, 'user.id')}`}
						onClick={(event) => {
							event.preventDefault();
							navigateTo(`/employees/${get(referral, 'user.id')}`);
						}}
					>
						{fullName(get(referral, 'user'))}
					</a>
				</li>
			</ul>

			<ul className="label-data">
				<li>
					<label>
						{ml('Referred Type', currentUser, allMultiLingualData)}:
					</label>
					{capitalizeFirstLetter(get(referral, 'referralType'))}
				</li>
			</ul>

			<ul className="label-data">
				<li>
					<label>
						{ml('Referred Date', currentUser, allMultiLingualData)}:
					</label>
					{formattedDate(get(referral, 'referralDate'))}
				</li>
			</ul>

			<ul className="label-data">
				<li>
					<label>
						{ml('Logs', currentUser, allMultiLingualData)}:
					</label>
				</li>
			</ul>
			<div
				className="log-box"
				style={{
					maxHeight: '150px',
					minHeight: '100px',
					display: 'block',
					width: '100%'
				}}
			>
				<div id="log">
					{renderLogs()}
				</div>
			</div>
			<div className="modal-footer-btn">
				<Button
					type="primary"
					className="btn-min-width"
					size="large"
					onClick={() => handleReferralCancel()}
				>
					Close
				</Button>
			</div>
		</>
	);
}

export default ReferralLogsModal;
