export const updateBonusAction = `mutation UpdateBonus($input: UpdateBonusInput!) {
    updateBonus(input: $input) {
        id
        amountDue
        startDate
        earnedDate
        payment
        recipientType
        bonusStatus
        notes
        paidDate
    }
  }
  `;
