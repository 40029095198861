import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import {
	DeleteOutlined,
	InfoCircleOutlined,
	LoadingOutlined,
	PlusOutlined,
	QuestionCircleOutlined,
	SearchOutlined,
} from '@ant-design/icons';
import { Editor } from '@hugerte/hugerte-react';
import {
	Button,
	Col,
	Input,
	List,
	Modal,
	Popconfirm,
	Row,
	Skeleton,
	Spin,
	Tooltip,
	Upload,
	message,
} from 'antd';
import DOMPurify from 'dompurify';
import { css } from 'emotion';
import gql from 'graphql-tag';
import Cookies from 'js-cookie';
import _, { get, startCase } from 'lodash';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import ReactGA from 'react-ga';
import { BsBoxArrowUpRight } from 'react-icons/bs';
import ProgressButton from 'react-progress-button';
import { Link } from 'react-router-dom';
import { GetJobForMatch } from 'src/_shared/api/graphql/custom/jobMatch/';
import { getUserById } from 'src/_shared/api/graphql/custom/users/';
import BannerImg from 'src/_shared/assets/banner-bg.jpeg';
import fileIcon from 'src/_shared/assets/erin_lightgray.png';
import { USER_ROLES } from 'src/_shared/constants/';
import { Spinner, WebIcon } from 'src/_shared/index.js';
import {
	downloadFromS3Signed,
	filterByDistance,
	formatDate,
	getCoordinates,
	getSetErrorImageURL,
	getSize,
	logout,
	ml,
	parse,
	searchOpenSearchNetwork,
	uploadImageToS3FromHugeRTE,
	uploadToS3Multipart,
} from 'src/_shared/services/utils.js';
import { COLORS } from 'src/_shared/styles/colors';
import { JobMatchCard } from 'src/dashboard/tiles/job-match-card';
import { getUserCareerProfileCompletionProgress } from 'src/helpers';
import { queryReferralsByUserIdReferralTypeIndex } from 'src/my-applications';
import MyApplicationsProgressIndicator from '../my-applications/ProgressIndicatorComponent.jsx';
import {
	LinkStyles,
	ModalStyles,
	ModalStylesMiddle,
	SubTitleLower,
	h1Style,
} from './internalStyles.js';
import { ProgressIndicator } from './progress-indicator/ProgressIndicatorComponent.jsx';

const sanitizer = DOMPurify.sanitize;

function beforeUpload(file) {
	const correctType = file.type === 'image/png' || file.type === 'image/jpeg';
	if (!correctType) {
		message.error('You can only upload png or jpeg files!');
	}

	const isLt2M = file.size / 1024 / 1024 < 2;
	if (!isLt2M) {
		message.error('Image must smaller than 2MB!');
	}

	return correctType && isLt2M;
}

class InternalComponent extends Component {
	constructor(props) {
		super(props);
		const extendedContactsData = get(
			props,
			'currentUser.extendedContactData',
			[]
		);
		let allJobMatches = extendedContactsData.flatMap((contact) =>
			get(contact, 'jobMatches')
		);
		allJobMatches = allJobMatches.filter(
			(match) => match !== undefined && get(match, 'active') === true
		);

		if (get(props, 'currentUser.role') !== 'extendedUser') {
			let userMatches = get(props, 'currentUser.jobMatches', []);
			userMatches = userMatches.filter(
				(match) =>
					!get(match, 'job.hideImInterested') &&
					!(
						get(
							props,
							'currentUser.company.allowSelfReferralsInternalLink',
							false
						) && !get(match, 'job.internalJobLink', null)
					) &&
					get(props, 'currentUser.companyId') === get(match, 'job.companyId')
			);
			allJobMatches.push(...userMatches);
		}

		let locationJobMatches = [];
		let departmentJobMatches = [];
		let allJobMatchesDataSource = [];
		let locationJobMatchesDataSource = [];
		let departmentJobMatchesDataSource = [];
		if (allJobMatches.length > 0) {
			allJobMatches = _.orderBy(allJobMatches, (job) => job?.relevance ?? 0, [
				'desc',
			]);
			const endMatch = {
				id: 'update',
				active: true,
			};
			allJobMatches = allJobMatches.map((match) => {
				match.job.location = parse(get(match, 'job.location'));
				return match;
			});
			locationJobMatches = allJobMatches.filter(
				(match) => get(match, 'matchType') === 'location'
			);
			departmentJobMatches = allJobMatches.filter(
				(match) => get(match, 'matchType') === 'department'
			);
			allJobMatches = allJobMatches.filter(
				(match) => get(match, 'matchType') === 'all'
			);
			allJobMatchesDataSource = [...allJobMatches, endMatch];
			locationJobMatchesDataSource = [...locationJobMatches, endMatch];
			departmentJobMatchesDataSource = [...departmentJobMatches, endMatch];
		}

		this.state = {
			allJobMatches,
			locationJobMatches,
			departmentJobMatches,
			allJobMatchesDataSource,
			locationJobMatchesDataSource,
			departmentJobMatchesDataSource,
			theme: JSON.parse(get(props, 'currentUser.company.theme', '{}')),
			visible: false,
			lengthError: false,
			loaded: false,
			edit: false,
			buttonStateFirst: '',
			editorKeyFirst: 4,
			ContentFirst: '',
			ContentLoading: true,
			buttonStateMiddle: '',
			editorKeyMiddle: 4,
			ContentMiddle: '',
			buttonStateLast: '',
			editorKeyLast: 4,
			ContentLast: '',
			filteredCompanies: [],
			filteredDepartments: [],
			searchedJobs: [],
			filteredBonuses: [],
			filteredJobs: get(props, 'jobs', []),
			allJobs: get(props, 'jobs', []),
			searchQuery: '',
			loadingJobMatches: true,
			isExtendedUser: get(props, 'currentUser.role') === 'extendedUser',
			page: 0,
			errors: {},
			filteredDistance: get(props, 'currentUser.defaultDistance')
				? get(props, 'currentUser.defaultDistance')
				: get(props, 'currentUser.company.defaultLocation')
					? get(props, 'currentUser.company.defaultLocation')
					: '0',
			extendedNetworkUsers: get(
				this.props,
				'currentUser.extendedContactData',
				[]
			).map((contact) => get(contact, 'user')),
			companyData: props.companyData,
			currencyRate: 0,
			currencySymbol: '$',
			selfReferrals: null,
			loadingSelfReferrals: true,
			searchedValue: '',
			appliedFilters: [],
			internalMobility: this.props?.internalMobility?.[0],
			internalMobilityImage: get(
				props,
				'currentUser.company.internalMobilityImage'
			),
			visibleFirst: false,
			editFirst: false,
			visibleMiddle: false,
			editMiddle: false,
			visibleLast: false,
			editLast: false,
			careerProgress: getUserCareerProfileCompletionProgress(null),
			errorImageURL: '',
			internalMobilityImageURL: '',
		};
	}

	async componentDidMount() {
		this.handleResize();
		window.addEventListener('resize', this.handleResize);
		const host = window.location.hostname;
		if (host === 'referrals.aus.com') {
			ReactGA.initialize('UA-128630809-2');
			ReactGA.pageview(window.location.pathname + window.location.search);
		} else if (host === 'app.erinapp.com') {
			ReactGA.initialize('UA-128630809-3');
			ReactGA.pageview(window.location.pathname + window.location.search);
		}

		await this.getUserDataById();
		await this.middleSection();

		const jwt = Cookies.get('jwt');
		const { resultData } = this.state;
		if (resultData !== '' && resultData !== undefined) {
			const expirationDoneByToken = get(
				resultData,
				'expirationDoneByToken',
				null
			);
			const expires = get(resultData, 'expires');
			if (jwt !== undefined && jwt !== expirationDoneByToken && expires) {
				logout(this.props.client);
			}
		}

		await this.getReferralsOfCurrentUser();
		await this.setUserCareerProgress();

		const { currentUser } = this.props;
		const errorImageURL = await getSetErrorImageURL(
			currentUser?.company?.errorImage?.key
		);

		if (errorImageURL) {
			this.setState({ errorImageURL });
		}

		if (this.state.internalMobilityImage) {
			const presignedURL = await downloadFromS3Signed(
				this.state.internalMobilityImage.key
			);
			this.setState({ internalMobilityImageURL: presignedURL });
		}

		if (
			currentUser?.role !== USER_ROLES.EXTENDED_USER &&
			this.state.allJobMatches.length <= 0
		) {
			await this.getTemporaryJobMatches();
		} else {
			this.setState({ loadingJobMatches: false });
		}
	}

	async componentDidUpdate(prevProps) {
		if (this.props?.internalMobility !== prevProps?.internalMobility) {
			// Update Internal Mobility info cards
			await this.middleSection();
		}
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize);
	}

	getImgSrc = (imgString) => {
		const div = document.createElement('div');
		div.innerHTML = imgString;
		const img = div.querySelector('img');
		return img.src;
	};

	getJobCount = (jobs) => {
		return jobs.filter((job) => job.id !== 'update').length;
	};

	async getReferralsOfCurrentUser(
		policy = 'network-only',
		nextToken = null,
		selfReferrals = []
	) {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: queryReferralsByUserIdReferralTypeIndex,
					variables: {
						userId,
						referralType: 'self',
						nextToken,
					},
					fetchPolicy: policy,
				});
				const referrals = [
					...data.queryReferralsByUserIdReferralTypeIndex.items,
				];
				const token = get(
					data,
					'queryReferralsByUserIdReferralTypeIndex.nextToken',
					null
				);
				selfReferrals = [...selfReferrals, ...referrals];
				let allSelfReferrals = [];

				if (token) {
					await this.getReferralsOfCurrentUser(policy, token, selfReferrals);
				}

				allSelfReferrals = _.sortBy(selfReferrals, ['referralDate']).reverse();
				this.setState({
					selfReferrals: allSelfReferrals.filter((referral) => referral.job),
				});
			}
		} catch (error) {
			console.log(error);
		}

		this.setState({ loadingSelfReferrals: false });
	}

	async getTemporaryJobMatches(searchCriteria) {
		try {
			const careerProfile = this.props.currentUser?.careerProfile
				? parse(this.props.currentUser.careerProfile)
				: null;
			let careerProfileQuery = '';
			if (careerProfile) {
				let wordCount = 0;
				if (careerProfile?.employment?.length > 0) {
					for (const [index, job] of careerProfile.employment.entries()) {
						if (job?.title && wordCount < 11) {
							const wordsInTitle = job.title.split(' ').length;
							careerProfileQuery += ' ' + job.title;
							wordCount += wordsInTitle;
						}
					}
				}

				if (careerProfile?.skills?.length > 0) {
					for (const [index, skill] of careerProfile.skills.entries()) {
						if (wordCount < 11) {
							const wordsInSkill = skill.split(' ').length;
							careerProfileQuery += ' ' + skill;
							wordCount += wordsInSkill;
						}
					}
				}
			}

			const defaultDistance = Number.parseInt(
				this.props.currentUser?.defaultDistance
			);
			const defaultLocation = Number.parseInt(
				this.props.currentUser?.company?.defaultLocation
			);
			const filterDistance =
				defaultDistance && defaultDistance > 0
					? Number.parseInt(defaultDistance)
					: defaultLocation && defaultLocation > 0
						? Number.parseInt(defaultLocation)
						: 50;
			const userLocation = parse(this.props.currentUser?.location);
			const { lat, lng } =
				userLocation?.lat && userLocation?.lng
					? userLocation
					: (await getCoordinates(userLocation)) ?? {
							lat: undefined,
							lng: undefined,
						};
			const parameters = {
				query:
					careerProfileQuery?.length > 0
						? careerProfileQuery.trim()
						: get(this.props, 'currentUser.title'),
				size: 15,
				role: get(this.props, 'currentUser.role'),
				currentUser: get(this.props, 'currentUser.id'),
				filters: {
					companies: [get(this.props, 'currentUser.companyId')],
					status: 'open',
				},
			};
			const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');

			const temporaryMatchesAll = get(response, 'data', []);
			const temporaryMatchesLocation = await filterByDistance(
				temporaryMatchesAll,
				filterDistance,
				lat,
				lng
			);
			const temporaryMatchesDepartment = temporaryMatchesAll.filter(
				(match) =>
					match?.department?.id === this.props?.currentUser?.department?.id
			);

			const top15Dict = {
				all: temporaryMatchesAll.slice(0, 15),
				location: temporaryMatchesLocation.slice(0, 15),
				department: temporaryMatchesDepartment.slice(0, 15),
			};

			const matches = {
				all: [],
				location: [],
				department: [],
			};
			await Promise.all(
				Object.entries(top15Dict).map(async ([key, top15List]) => {
					await Promise.all(
						top15List.map(async (item, index) => {
							const { data } = await this.props.client.query({
								query: GetJobForMatch,
								variables: {
									id: get(item, 'jobId'),
								},
							});
							const job = get(data, 'getJob', {});
							const match = {
								active: true,
								contactId: null,
								dateCreated: new Date().toISOString(),
								id: `${index}`,
								jobId: get(job, 'id'),
								job,
								matchStatus: null,
								relevance: get(item, 'metaScore'),
								userId: get(this.props, 'currentUser.id'),
								temporary: true,
							};
							if (
								this.props.currentUser.role !== USER_ROLES.EXTENDED_USER &&
								get(
									this.props,
									'currentUser.company.allowSelfReferralsInternalLink',
									false
								) &&
								!get(match, 'job.internalJobLink', null)
							) {
								// do nothing
							} else {
								matches[key].push(match);
							}
						})
					);
				})
			);
			const endMatch = {
				id: 'update',
				active: true,
			};
			this.setState((_prevState) => ({
				allJobMatches: [...matches.all, endMatch],
				allJobMatchesDataSource: [...matches.all, endMatch],
				loadingJobMatches: false,
				locationJobMatches: [...matches.location, endMatch],
				locationJobMatchesDataSource: [...matches.location, endMatch],
				departmentJobMatches: [...matches.department, endMatch],
				departmentJobMatchesDataSource: [...matches.department, endMatch],
			}));
		} catch (error) {
			this.setState((prevState) => ({
				loadingJobMatches: false,
				errors: { ...prevState.errors, error },
			}));
		}
	}

	async getUserDataById(policy = 'network-only') {
		const { client, currentUser } = this.props;
		try {
			const userId = get(currentUser, 'id', null);
			if (userId !== null) {
				const { data } = await client.query({
					query: gql(getUserById),
					variables: {
						id: userId,
					},
					fetchPolicy: policy,
				});
				const result = get(data, 'getUser', null);
				this.setState({
					resultData: result,
				});
			}
		} catch (error) {
			console.log(error);
		}
	}

	async setUserCareerProgress() {
		const currentProgress = getUserCareerProfileCompletionProgress({
			careerProfile: this.props?.currentUser?.careerProfile,
			openToNewRole: this.props?.currentUser?.openToNewRole,
			selfReferrals: this.state.selfReferrals,
		});
		this.setState(() => ({ careerProgress: { ...currentProgress } }));
	}

	addInternalMobilityFirst = () => {
		const {
			onCreateInternalMobility,
			onUpdateInternalMobility,
			currentUser,
			internalMobility,
		} = this.props;
		const { ContentFirst, editFirst } = this.state;
		this.setState({
			buttonStateFirst: 'loading',
		});
		this.props.form.validateFields((error) => {
			if (error) {
				this.setState({
					buttonStateFirst: 'error',
				});
				setTimeout(() => {
					this.setState({
						buttonStateFirst: '',
					});
				}, 1500);
				return;
			}

			if (internalMobility && internalMobility.length > 0 && editFirst) {
				const input = {
					input: {
						id: get(internalMobility[0], 'id'),
						companyId: get(currentUser, 'companyId'),
					},
				};
				if (internalMobility && internalMobility.length > 0 && ContentFirst) {
					input.input.contentFirst = sanitizer(ContentFirst);
				}

				onUpdateInternalMobility(input);
				message.success('Content is updated successfully.', 5);
			} else {
				const input = {
					input: {
						companyId: get(currentUser, 'companyId'),
					},
				};
				if (ContentFirst) {
					input.input.contentFirst = sanitizer(ContentFirst);
				}

				onCreateInternalMobility(input);
				message.success('Content is added successfully.', 5);
			}

			setTimeout(() => {
				this.setState({
					buttonStateFirst: 'success',
				});
			}, 3000);
			this.props.form.resetFields();

			setTimeout(() => {
				this.handleCloseFirst();
			}, 3000);
			this.setState({
				buttonStateFirst: '',
			});
		});
	};

	addInternalMobilityLast = () => {
		const {
			onCreateInternalMobility,
			onUpdateInternalMobility,
			currentUser,
			internalMobility,
		} = this.props;
		const { ContentLast, editLast } = this.state;
		this.setState({
			buttonStateLast: 'loading',
		});
		this.props.form.validateFields((error) => {
			if (error) {
				this.setState({
					buttonStateLast: 'error',
				});
				setTimeout(() => {
					this.setState({
						buttonStateLast: '',
					});
				}, 1500);
				return;
			}

			if (internalMobility && internalMobility.length > 0 && editLast) {
				const input = {
					input: {
						id: get(internalMobility[0], 'id'),
						companyId: get(currentUser, 'companyId'),
					},
				};
				if (internalMobility && internalMobility.length > 0 && ContentLast) {
					input.input.contentLast = sanitizer(ContentLast);
				}

				onUpdateInternalMobility(input);
				message.success('Content is updated successfully.', 5);
			} else {
				const input = {
					input: {
						companyId: get(currentUser, 'companyId'),
					},
				};
				if (ContentLast) {
					input.input.contentLast = sanitizer(ContentLast);
				}

				onCreateInternalMobility(input);
				message.success('Content is added successfully.', 5);
			}

			setTimeout(() => {
				this.setState({
					buttonStateLast: 'success',
				});
			}, 3000);
			this.props.form.resetFields();

			setTimeout(() => {
				this.handleCloseLast();
			}, 3000);
			this.setState({
				buttonStateLast: '',
			});
		});
	};

	addInternalMobilityMiddle = () => {
		const {
			onCreateInternalMobility,
			onUpdateInternalMobility,
			currentUser,
			internalMobility,
		} = this.props;
		const { ContentMiddle, editMiddle } = this.state;
		this.setState({
			buttonStateFirst: 'loading',
		});
		this.props.form.validateFields((error) => {
			if (error) {
				this.setState({
					buttonStateMiddle: 'error',
				});
				setTimeout(() => {
					this.setState({
						buttonStateMiddle: '',
					});
				}, 1500);
				return;
			}

			if (internalMobility && internalMobility.length > 0 && editMiddle) {
				const input = {
					input: {
						id: get(internalMobility[0], 'id'),
						companyId: get(currentUser, 'companyId'),
					},
				};
				if (internalMobility && internalMobility.length > 0 && ContentMiddle) {
					input.input.contentMiddle = sanitizer(ContentMiddle);
				}

				onUpdateInternalMobility(input);
				message.success('Content is updated successfully.', 5);
			} else {
				const input = {
					input: {
						companyId: get(currentUser, 'companyId'),
					},
				};
				if (ContentMiddle) {
					input.input.contentMiddle = sanitizer(ContentMiddle);
				}

				onCreateInternalMobility(input);
				message.success('Content is added successfully.', 5);
			}

			setTimeout(() => {
				this.setState({
					buttonStateMiddle: 'success',
				});
			}, 3000);
			this.props.form.resetFields();

			setTimeout(() => {
				this.handleCloseMiddle();
			}, 3000);
			this.setState({
				buttonStateMiddle: '',
			});
		});
	};

	clearEditorFirst = () => {
		const newKey = this.state.editorKeyFirst * 43;
		this.setState({
			editorKeyFirst: newKey,
		});
	};

	clearEditorLast = () => {
		const newKey = this.state.editorKeyLast * 43;
		this.setState({
			editorKeyLast: newKey,
		});
	};

	clearEditorMiddle = () => {
		const newKey = this.state.editorKeyMiddle * 43;
		this.setState({
			editorKeyMiddle: newKey,
		});
	};

	/**
	 * Used to ensure that final page will display the appropriate number of job cards, set by pageSize
	 * @param {int} page - the current page the user is displaying
	 * @param {int} pageSize - the number of cards to display per page, set dynamically by this.state.windowDimensions
	 * @param {Object[]} jobs - the dataSource of jobs to be displayed in the list
	 * @param {string} nameOfDataSource - the name of the job match data source in state
	 */

	customLoader = (message) => {
		const antIcon = <LoadingOutlined spin style={{ fontSize: 24 }} />;
		return (
			<div className="custom-loader">
				<Spin indicator={antIcon} />
				<p>{message}</p>
			</div>
		);
	};

	decodeHtml(html) {
		const txt = document.createElement('textarea');
		txt.innerHTML = html;
		return txt.value;
	}

	deleteInternalMobilityResource = (resource, clearFunction) => {
		const { onUpdateInternalMobility, currentUser, internalMobility } =
			this.props;
		if (internalMobility?.[0]) {
			const updatedInternalMobility = {
				input: {
					id: internalMobility[0]?.id,
					companyId: currentUser?.companyId,
					[resource]: null,
				},
			};
			onUpdateInternalMobility(updatedInternalMobility);
		}

		const stateName = resource.charAt(0).toUpperCase() + resource.slice(1);
		clearFunction();
		this.setState({ [stateName]: null });
		message.success('Resource successfully deleted');
	};

	editFirst = () => {
		this.setState({
			visibleFirst: true,
			editFirst: true,
		});
	};

	editIcon = () => (
		<div className="edit-banner">
			<Button type="link" onClick={() => this.editInfo()}>
				<WebIcon name="edit" color={COLORS.blue} />
				Edit
			</Button>
		</div>
	);

	editIconFirst = () => (
		<Button type="link" className={LinkStyles} onClick={() => this.editFirst()}>
			<WebIcon name="edit" color={COLORS.blue} />
			&nbsp; Edit
		</Button>
	);

	editIconLast = () => (
		<Button type="link" className={LinkStyles} onClick={() => this.editLast()}>
			<WebIcon name="edit" color={COLORS.blue} />
			&nbsp; Edit
		</Button>
	);

	editIconMiddle = () => (
		<Button
			type="link"
			className={LinkStyles}
			onClick={() => this.editMiddle()}
		>
			<WebIcon name="edit" color={COLORS.blue} />
			&nbsp; Edit
		</Button>
	);

	editInfo = () => {
		this.setState({
			visible: true,
			edit: true,
		});
	};

	editLast = () => {
		this.setState({
			visibleLast: true,
			editLast: true,
		});
	};

	editMiddle = () => {
		this.setState({
			visibleMiddle: true,
			editMiddle: true,
		});
	};

	filterJobs = (e) => {
		e.preventDefault();
		this.props.form.validateFields(async (error, values) => {
			const { updateCurrentUserSearchCriteria } = this.props;
			const searchCriteria = get(values, 'job');
			await updateCurrentUserSearchCriteria(searchCriteria);
			this.props.history.push('/browsejobs');
		});
	};

	handleCareerProfile = () => {
		this.props.history.push('/career');
	};

	handleClose = () => {
		this.setState({
			visible: false,
			edit: false,
		});
	};

	handleCloseFirst = () => {
		this.clearEditorFirst();
		this.setState({
			visibleFirst: false,
			editFirst: false,
		});
	};

	handleCloseLast = () => {
		this.clearEditorFirst();
		this.setState({
			visibleLast: false,
			editLast: false,
		});
	};

	handleCloseMiddle = () => {
		this.clearEditorFirst();
		this.setState({
			visibleMiddle: false,
			editMiddle: false,
		});
	};

	handleEditorChangeFirst = (content) => {
		this.setState({
			ContentFirst: content,
		});
	};

	handleEditorChangeLast = (content) => {
		this.setState({
			ContentLast: content,
		});
	};

	handleEditorChangeMiddle = (content) => {
		this.setState({
			ContentMiddle: content,
		});
	};

	handleResize = () => {
		const getWindowDimensions = () => {
			const { innerWidth: width, innerHeight: height } = window;
			return {
				width,
				height,
			};
		};

		const windowDimensions = getWindowDimensions();
		this.setState({ windowDimensions });
	};

	internalMobilityImageRequest = async ({ file, onSuccess, onError }) => {
		console.log('starting request');
		const { onUpdateCompany, updateCurrentUserCompany } = this.props;
		const company = get(this.props, 'currentUser.company');
		setTimeout(() => {
			onSuccess('ok');
		}, 0);
		try {
			const newInternalMobilityImage = {
				bucket: 'erin-avatars',
				key: `internal-mobility/${company.id}/${file.name}`,
				region: 'us-east-2',
			};

			await uploadToS3Multipart(
				file,
				newInternalMobilityImage.key,
				newInternalMobilityImage.bucket
			);

			onSuccess(newInternalMobilityImage);
			const presignedURL = await downloadFromS3Signed(
				newInternalMobilityImage.key
			);
			this.setState({
				internalMobilityImage: newInternalMobilityImage,
				internalMobilityImageChanged: true,
				internalMobilityImageURL: presignedURL,
			});
			const updatedCompany = {
				id: company.id,
				internalMobilityImage: newInternalMobilityImage,
			};
			onUpdateCompany({
				input: updatedCompany,
			});

			updateCurrentUserCompany({
				...company,
				id: updatedCompany.id,
				internalMobilityImage: updatedCompany.internalMobilityImage,
			});

			message.success('Changes Saved');
			this.handleClose();
		} catch (error) {
			console.error(error);
			onError(error);
		}
	};

	async middleSection() {
		const first =
			get(this.props, 'internalMobility') &&
			get(this.props, 'internalMobility').length > 0 &&
			get(this.props, 'internalMobility')[0].contentFirst
				? get(this.props, 'internalMobility')[0].contentFirst
				: '';
		const middle =
			get(this.props, 'internalMobility') &&
			get(this.props, 'internalMobility').length > 0 &&
			get(this.props, 'internalMobility')[0].contentMiddle
				? get(this.props, 'internalMobility')[0].contentMiddle
				: '';
		const last =
			get(this.props, 'internalMobility') &&
			get(this.props, 'internalMobility').length > 0 &&
			get(this.props, 'internalMobility')[0].contentLast
				? get(this.props, 'internalMobility')[0].contentLast
				: '';
		let newContentFirst = this.decodeHtml(first);
		let newContentMiddle = this.decodeHtml(middle);
		let newContentLast = this.decodeHtml(last);
		if (
			newContentFirst.includes('img') &&
			newContentFirst.includes('https://erin-avatars.')
		) {
			const src = this.getImgSrc(newContentFirst);
			let spilitedData = src.split('.com/');
			spilitedData = spilitedData[1].split('?');
			const key = spilitedData[0];
			const url = await downloadFromS3Signed(key);
			newContentFirst = newContentFirst.replaceAll(
				/src=['"](?:[^"'/]*\/)*([^'"]+)['"]/g,
				"src='" + url + "'"
			);
		}

		if (
			newContentMiddle.includes('img') &&
			newContentMiddle.includes('https://erin-avatars.')
		) {
			const src = this.getImgSrc(newContentMiddle);
			let spilitedData = src.split('.com/');
			spilitedData = spilitedData[1].split('?');
			const key = spilitedData[0];
			const url = await downloadFromS3Signed(key);
			newContentMiddle = newContentMiddle.replaceAll(
				/src=['"](?:[^"'/]*\/)*([^'"]+)['"]/g,
				"src='" + url + "'"
			);
		}

		if (
			newContentLast.includes('img') &&
			newContentLast.includes('https://erin-avatars.')
		) {
			const src = this.getImgSrc(newContentLast);
			let spilitedData = src.split('.com/');
			spilitedData = spilitedData[1].split('?');
			const key = spilitedData[0];
			const url = await downloadFromS3Signed(key);
			newContentLast = newContentLast.replaceAll(
				/src=['"](?:[^"'/]*\/)*([^'"]+)['"]/g,
				"src='" + url + "'"
			);
		}

		this.setState({
			ContentFirst: newContentFirst,
			ContentMiddle: newContentMiddle,
			ContentLast: newContentLast,
			ContentLoading: false,
		});
	}

	newHugeRteFirst = () => {
		return (
			<div
				style={{
					height: 350,
					fontFamily: 'inherit',
					width: '100%',
					marginTop: 20,
				}}
			>
				<Editor
  key={this.state.editorKeyFirst}
  init={{
    height: 350,
    menubar: false,
    branding: false,
    plugins: ['image', 'code', 'media', 'link'],
    toolbar: 'undo redo | styles | fontfamily |bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent  | \
            media link image ',
    images_upload_handler: this.uploadImagePromise,
    automatic_uploads: true,
    file_picker_types: 'image',
    max_width: '100%',
    placeholder: 'Start typing here to add Internal Mobility Resources...',
    content_style: `img {
      max-width: 100%;
    }
    .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      color: rgba(118, 118, 118, 1);
      opacity: 1;
    }`,
  }}
  value={this.state.ContentFirst}
  onEditorChange={this.handleEditorChangeFirst}
/>
			</div>
		);
	};

	newHugeRteLast = () => {
		return (
			<div
				style={{
					height: 350,
					fontFamily: 'inherit',
					width: '100%',
					marginTop: 20,
				}}
			>
				<Editor
					key={this.state.editorKeyLast}
					init={{
						height: 350,
						menubar: false,
						branding: false,
						plugins: ['image', 'code', 'media', 'link'],
						toolbar:
							'undo redo | styles | fontfamily |bold italic backcolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent  | \
						 	media link image ',
						images_upload_handler: this.uploadImagePromise,
						automatic_uploads: true,
						file_picker_types: 'image',
						max_width: '100%',
						placeholder:
							'Start typing here to add Internal Mobility Resources...',
						content_style: `img {
	max-width: 100%;
	}
	.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
	color: rgba(118, 118, 118, 1);
	opacity: 1;
	}`,
					}}
					value={this.state.ContentLast}
					onEditorChange={this.handleEditorChangeLast}
				/>
			</div>
		);
	};

	newHugeRteMiddle = () => {
		return (
			<div
				style={{
					height: 350,
					fontFamily: 'inherit',
					width: '100%',
					marginTop: 20,
				}}
			>
				<Editor
					key={this.state.editorKeyMiddle}
					init={{
						height: 350,
						menubar: false,
						branding: false,
						plugins: ['image', 'code', 'media', 'link'],
						toolbar:
							'undo redo | styles | fontfamily |bold italic backcolor | \
							alignleft aligncenter alignright alignjustify | \
							bullist numlist outdent indent  | \
						 	media link image ',
						images_upload_handler: this.uploadImagePromise,
						automatic_uploads: true,
						file_picker_types: 'image',
						max_width: '100%',
						placeholder:
							'Start typing here to add Internal Mobility Resources...',
						content_style: `img {
	max-width: 100%;
	}
	.mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
	color: rgba(118, 118, 118, 1);
	opacity: 1;
	}`,
					}}
					value={this.state.ContentMiddle}
					onEditorChange={this.handleEditorChangeMiddle}
				/>
			</div>
		);
	};

	notInterested = (match) => {
		const input = {
			id: get(match, 'id'),
			companyId: get(match, 'job.companyId'),
			active: false,
		};
		if (!get(match, 'temporary')) this.props.onUpdateJobMatch(input);
		let allJobMatches = get(this.state, 'allJobMatches', []);
		const index = allJobMatches.findIndex((m) => m.id === match.id);
		const updatedMatch =
			index >= 0 ? { ...allJobMatches[index], ...input } : null;

		if (updatedMatch) {
			allJobMatches[index] = updatedMatch;
		}

		const temporaryMatches = [];
		if (get(match, 'temporary')) {
			allJobMatches = allJobMatches.filter(
				(match) => match.id !== 'update' && match.active !== false
			);
			allJobMatches.map((match, index) => {
				temporaryMatches.push(match);
				if (index === allJobMatches.length - 1)
					temporaryMatches.push({
						id: 'update',
						active: true,
					});
			});
		}

		const currentUser = get(this.props, 'currentUser');
		currentUser.jobMatches = allJobMatches;
		if (get(match, 'temporary')) {
			this.setState({
				allJobMatches: temporaryMatches,
				allJobMatchesDataSource: temporaryMatches,
			});
		} else {
			this.props.setCurrentUser(currentUser);
		}
	};

	removeInternalMobilityImage = async () => {
		const { onUpdateCompany, updateCurrentUserCompany } = this.props;
		const company = get(this.props, 'currentUser.company');

		try {
			this.setState({
				internalMobilityImage: null,
				internalMobilityImageChanged: true,
			});
			const updates = {
				id: company.id,
				internalMobilityImage: null,
			};
			onUpdateCompany({
				input: updates,
			});
			updateCurrentUserCompany({
				...company,
				id: updates.id,
				internalMobilityImage: null,
			});

			message.success('Changes Saved');

			this.handleClose();
		} catch (error) {
			console.error(error);
		}
	};

	uploadImagePromise = (blobInfo, progress) => {
		return new Promise(async (resolve, reject) => {
		  console.log("Starting image upload...");
		  console.log("BlobInfo:", {
			name: blobInfo.filename(),
			type: blobInfo.blob().type,
			size: blobInfo.blob().size
		  });
		  
		  try {
			const image = blobInfo.base64();
			const file = blobInfo.blob();
			const companyId = this.props?.companyId;
			
			if (typeof progress === 'function') {
			  progress(10);
			}
			
			const uploadFile = await uploadImageToS3FromHugeRTE(
			  image,
			  file,
			  `internal-mobility/${companyId}/first/${Date.now()}-${file.name}`
			);
			
			if (typeof progress === 'function') {
			  progress(50);
			}
			
			const link = await downloadFromS3Signed(uploadFile.key, uploadFile.bucket);
			console.log("Image uploaded successfully, URL:", link);
			
			if (typeof progress === 'function') {
			  progress(100);
			}
			
			resolve(link);
		  } catch (error) {
			console.error('Upload failed:', error);
			reject(error.message || 'Image upload failed');
			message.error('Image upload failed: ' + error.message);
		  }
		});
	};

	/**
	 * Displays a message to the user, using the appropriate company logo
	 *
	 * @param {string} message
	 * @param {boolean} companyUI
	 */
	renderUIStatusMessage = (message, companyUI) => {
		const { errorImageURL } = this.state;
		return (
			<div className="no-content">
				{companyUI?.whiteLabel ? (
					<img
						src={errorImageURL}
						alt="error image"
						className="no-content-icon"
					/>
				) : (
					<img alt="erin-logo" className="no-content-icon" src={fileIcon} />
				)}
				<p className="no-content-text">{message}</p>
			</div>
		);
	};

	render() {
		const { allMultiLingualData, currentUser } = this.props;
		const { getFieldDecorator } = this.props.form;
		const FormItem = Form.Item;
		const {
			allJobMatches,
			departmentJobMatches,
			locationJobMatches,
			allJobMatchesDataSource,
			departmentJobMatchesDataSource,
			locationJobMatchesDataSource,
			selfReferrals,
			searchedValue,
			appliedFilters,
			internalMobility,
			internalMobilityImage,
			visible,
			theme,
			visibleFirst,
			visibleMiddle,
			visibleLast,
			ContentFirst,
			ContentMiddle,
			ContentLast,
			careerProgress,
			ContentLoading,
			loadingJobMatches,
			loadingSelfReferrals,
			internalMobilityImageURL,
		} = this.state;
		const company = get(this.props, 'currentUser.company');
		const displayAs = get(currentUser, 'displayAs');
		const whiteLabel = get(currentUser, 'company.whiteLabel');
		const errorImage = get(currentUser, 'company.errorImage.key', false);
		const companyUI = { whiteLabel, errorImage };
		const fileListInternalMobilityImage = internalMobilityImage
			? [
					{
						uid: '-1',
						name: internalMobilityImage.key,
						status: 'done',
						url: internalMobilityImageURL,
					},
				]
			: [];
		const backgroundImage = internalMobilityImage
			? internalMobilityImageURL
			: BannerImg;

		const pageSize = getSize(this.state.windowDimensions);
		const halfPageSize = Math.ceil(pageSize / 2);

		if (!get(currentUser, 'company')) return <Spinner />;
		return (
			<>
				<div className="internal-banner">
					<img src={backgroundImage} className="banner-img" />
					{displayAs === USER_ROLES.ADMIN && this.editIcon()}

					<div className="banner-content">
						<div className="c-left">
							<p>
								Find Your Next Job at
								<strong>{get(company, 'name')}</strong>
							</p>
							<form className="search-input" onSubmit={this.filterJobs}>
								{getFieldDecorator('job', {
									initialValue: '',
								})(<Input placeholder="Search by skills, location, etc." />)}
								<Button className="search-icon" htmlType="submit">
									<SearchOutlined />
								</Button>
							</form>
						</div>
						<div className="c-right">
							<p>
								Be matched with the <strong>perfect job for you.</strong>
							</p>
							<Button href="/career" className="announcements-update-btn">
								Update Career Profile
							</Button>
						</div>
					</div>
				</div>

				<main>
					{displayAs === USER_ROLES.ADMIN ? (
						<section className="im-resource" id="internal-mobility-resources">
							<h3 className="sub-title">
								{ml(
									'Internal Mobility Resources',
									currentUser,
									allMultiLingualData
								)}
							</h3>
							<Row gutter={12}>
								<Col sm={24} md={8}>
									<div className="resource-box">
										{this.editIconFirst()}
										{ContentFirst ? (
											<div
												dangerouslySetInnerHTML={{
													__html: sanitizer(ContentFirst),
												}}
												className="resource-content"
											/>
										) : ContentLoading ? (
											<Skeleton active />
										) : (
											<p>Add Internal Mobility Resources for Your Employees</p>
										)}
									</div>
								</Col>

								<Col sm={24} md={8}>
									<div className="resource-box">
										{this.editIconMiddle()}
										{ContentMiddle ? (
											<div
												dangerouslySetInnerHTML={{
													__html: sanitizer(ContentMiddle),
												}}
												className="resource-content"
											/>
										) : ContentLoading ? (
											<Skeleton active />
										) : (
											<p>Add Internal Mobility Resources for Your Employees</p>
										)}
									</div>
								</Col>
								<Col sm={24} md={8}>
									<div className="resource-box">
										{this.editIconLast()}
										{ContentLast ? (
											<div
												dangerouslySetInnerHTML={{
													__html: sanitizer(ContentLast),
												}}
												className="resource-content"
											/>
										) : ContentLoading ? (
											<Skeleton active />
										) : (
											'Add Internal Mobility Resources for Your Employees'
										)}
									</div>
								</Col>
							</Row>
						</section>
					) : internalMobility &&
					  (internalMobility?.contentFirst ||
							internalMobility?.contentMiddle ||
							internalMobility?.contentLast) ? (
						<section className="im-resource" id="internal-mobility-resources">
							<h3 className="sub-title">
								{ml(
									'Internal Mobility Resources',
									currentUser,
									allMultiLingualData
								)}
							</h3>
							<Row gutter={12}>
								{internalMobility?.contentFirst && (
									<Col sm={24} md={8}>
										<div className="resource-box">
											{ContentFirst ? (
												<div
													dangerouslySetInnerHTML={{
														__html: sanitizer(ContentFirst),
													}}
													className="resource-content"
												/>
											) : ContentLoading ? (
												<Skeleton active />
											) : (
												<p>This resource could not be loaded.</p>
											)}
										</div>
									</Col>
								)}
								{internalMobility?.contentMiddle && (
									<Col sm={24} md={8}>
										<div className="resource-box">
											{ContentMiddle ? (
												<div
													dangerouslySetInnerHTML={{
														__html: sanitizer(ContentMiddle),
													}}
													className="resource-content"
												/>
											) : ContentLoading ? (
												<Skeleton active />
											) : (
												<p>This resource could not be loaded.</p>
											)}
										</div>
									</Col>
								)}
								{internalMobility?.contentLast && (
									<Col sm={24} md={8}>
										<div className="resource-box">
											{ContentLast ? (
												<div
													dangerouslySetInnerHTML={{
														__html: sanitizer(ContentLast),
													}}
													className="resource-content"
												/>
											) : ContentLoading ? (
												<Skeleton active />
											) : (
												<p>This resource could not be loaded.</p>
											)}
										</div>
									</Col>
								)}
							</Row>
						</section>
					) : null}

					<section className="your-applications-wrap">
						<Row gutter={12} type="flex">
							<Col sm={24} md={10}>
								<h3 className="sub-title">
									{ml(
										'Internal Mobility Onboarding',
										currentUser,
										allMultiLingualData
									)}
								</h3>
								<section className="progress-wrap">
									<ProgressIndicator careerProgress={careerProgress} />
								</section>
							</Col>
							<Col sm={24} md={14}>
								<Row type="flex" justify="space-between">
									<Col>
										<h3 className="sub-title">
											{ml(
												'Your Applications',
												currentUser,
												allMultiLingualData
											)}
										</h3>
									</Col>
									<Col>
										<Link
											to="/myapplications/"
											target="_blank"
											rel="noopener noreferrer"
										>
											{ml('Show All', currentUser, allMultiLingualData)}
										</Link>
									</Col>
								</Row>
								<section className="your-applications">
									{selfReferrals && selfReferrals.length > 0 ? (
										<>
											{!selfReferrals.some((referral) =>
												referral.job.title
													.toLowerCase()
													.includes(searchedValue.toLowerCase())
											) ||
											(appliedFilters.length > 0 &&
												!selfReferrals.some((referral) =>
													appliedFilters.includes(referral.status)
												)) ? (
												this.renderUIStatusMessage(
													'There are no matches for your search criteria.',
													companyUI
												)
											) : (
												<List
													grid={{
														gutter: 12,
														xs: 1,
														sm: 1,
														md: 1,
														lg: 1,
														xl: 2,
														xxl: 2,
													}}
													dataSource={selfReferrals.filter((referral) => {
														// Provide all referrals by default, filter out values that don't match search/filter criteria
														if (
															(appliedFilters.length > 0 &&
																!appliedFilters.includes(referral.status)) ||
															!referral.job.title
																.toLowerCase()
																.includes(searchedValue.toLowerCase())
														) {
															return false;
														}

														return true;
													})}
													itemLayout="horizontal"
													pagination={{
														responsive: true,
														showLessItems: true,
														pageSize: halfPageSize,
														hideOnSinglePage: true,
													}}
													style={{ width: '100%' }}
													renderItem={(referral) => (
														<List.Item
															key={referral.id}
															style={{ height: '100%' }}
														>
															<div
																style={{
																	display: 'flex',
																	flexDirection: 'column',
																}}
																className="my-progressbar-card"
															>
																<div className="my-progressbar-top">
																	<div className="mp-left">
																		<h4 className="my-progressbar-name">
																			<Link
																				to={`/browsejobs/${referral.job.id}`}
																			>
																				{referral.job.title}
																			</Link>
																		</h4>
																	</div>
																	<div className="mp-right">
																		<span className="my-progressbar-date">
																			{ml(
																				'Applied On',
																				currentUser,
																				allMultiLingualData
																			)}
																			:&nbsp;
																			{/* Format date according to locale language code */}
																			{formatDate(
																				referral.referralDate,
																				currentUser.languageCode,
																				currentUser.dateFormat || 'US'
																			)}
																		</span>
																	</div>
																</div>
																<div className="flex px-[24px] py-[16px] h-[100px]">
																	{referral.job.internalJobLink &&
																	['accepted', 'referred'].includes(
																		referral.status
																	) ? (
																		<div className="text-erinBlue font-bold flex h-fit">
																			<a
																				href={referral.job.internalJobLink}
																				target="_blank"
																				rel="noreferrer"
																			>
																				Apply Link
																			</a>
																			<BsBoxArrowUpRight
																				style={{
																					marginBottom: '4px',
																					marginLeft: '4px',
																				}}
																			/>
																		</div>
																	) : null}
																</div>
																<div className="my-progressbar-status">
																	<div className="mps-left">
																		{ml(
																			'Status',
																			currentUser,
																			allMultiLingualData
																		)}
																		{': '}
																		{/* Format referral status from camel case, to sentence and capitalizes first */}
																		{/* Display started in place of accepted. And if status is "interviewing" but customStatus exists, display custom status in its place  */}
																		<span className="status-name">
																			{startCase(
																				referral.status === 'accepted'
																					? 'started'
																					: referral.status === 'noresponse'
																						? 'No Response'
																						: referral.customStatus &&
																							  referral.status ===
																									'interviewing'
																							? referral.customStatus
																							: referral.status
																			)}
																		</span>
																	</div>
																</div>
																<div className="my-progressbar-wrap">
																	<MyApplicationsProgressIndicator
																		referral={referral}
																	/>
																</div>
															</div>
														</List.Item>
													)}
												/>
											)}
										</>
									) : (
										<>
											{loadingSelfReferrals
												? this.customLoader('Looking for your applications')
												: this.renderUIStatusMessage(
														'No applications.',
														companyUI
													)}
										</>
									)}
								</section>
							</Col>
						</Row>
					</section>
					<section className="recommended-jobs-wrap">
						<h3 className="sub-title" id="recommended-jobs">
							{ml('Recommended Jobs', currentUser, allMultiLingualData)}
						</h3>
						<Row gutter={12} type="flex" style={{ marginBottom: 20 }}>
							<Col xs={8} md={4} lg={4} xl={4}>
								<div className="stat-box nearby">
									<Tooltip
										placement="right"
										title="Recommended jobs within 50 miles"
										className="stat-box-info"
									>
										<InfoCircleOutlined />
									</Tooltip>
									<div className="stats">
										<h4>{this.getJobCount(locationJobMatches)}</h4>
										<p>Nearby</p>
									</div>
								</div>
							</Col>
							<Col xs={16} md={20} lg={20} xl={20}>
								<div className="card-row recommended">
									{loadingJobMatches ? (
										this.customLoader('Looking for nearby jobs')
									) : locationJobMatches && locationJobMatches.length > 0 ? (
										<List
											dataSource={locationJobMatchesDataSource}
											grid={{
												gutter: 12,
												xs: 1,
												sm: 1,
												md: 2,
												lg: 2,
												xl: 3,
												xxl: 4,
											}}
											className="job-card-wrap"
											pagination={{
												responsive: true,
												showLessItems: true,
												pageSize,
												hideOnSinglePage: true,
											}}
											renderItem={(jobMatch) => (
												<List.Item>
													<JobMatchCard
														handleCareerProfile={this.handleCareerProfile}
														allMultiLingualData={allMultiLingualData}
														currentUser={this.props.currentUser}
														jobMatch={jobMatch}
														matchedJobs={locationJobMatches}
														notInterested={this.notInterested}
														allowSelfReferrals={get(
															this.props,
															'currentUser.company.allowSelfReferrals'
														)}
														allowSelfReferralsInternalLink={get(
															this.props,
															'currentUser.company.allowSelfReferralsInternalLink'
														)}
														theme={theme}
														fontWeight={300}
														fontSize={11}
														height={240}
														minHeight={240}
														width="100%"
														minWidth={220}
														incompleteCareerProfile={
															careerProgress.hasAddedSkills.value === false ||
															careerProgress.hasAddedExperience.value === false
														}
														onCreateReferral={this.props.onCreateReferral}
													/>
												</List.Item>
											)}
										/>
									) : (
										this.renderUIStatusMessage(
											'No recommended jobs nearby.',
											companyUI
										)
									)}
								</div>
							</Col>
						</Row>
						<Row gutter={12} type="flex" style={{ marginBottom: 20 }}>
							<Col xs={8} md={4} lg={4} xl={4}>
								<div className="stat-box department">
									<div className="stats">
										<h4>{this.getJobCount(departmentJobMatches)}</h4>
										<p>Your Department</p>
									</div>
								</div>
							</Col>
							<Col xs={16} md={20} lg={20} xl={20}>
								<div className="card-row recommended">
									{loadingJobMatches ? (
										this.customLoader('Looking for jobs in your department')
									) : departmentJobMatches &&
									  departmentJobMatches.length > 0 ? (
										<List
											dataSource={departmentJobMatchesDataSource}
											grid={{
												gutter: 12,
												xs: 1,
												sm: 1,
												md: 2,
												lg: 2,
												xl: 3,
												xxl: 4,
											}}
											className="job-card-wrap"
											itemLayout="horizontal"
											pagination={{
												responsive: true,
												showLessItems: true,
												pageSize,
												hideOnSinglePage: true,
											}}
											renderItem={(jobMatch) => (
												<List.Item>
													<JobMatchCard
														handleCareerProfile={this.handleCareerProfile}
														allMultiLingualData={allMultiLingualData}
														currentUser={this.props.currentUser}
														jobMatch={jobMatch}
														matchedJobs={departmentJobMatches}
														notInterested={this.notInterested}
														allowSelfReferrals={get(
															this.props,
															'currentUser.company.allowSelfReferrals'
														)}
														allowSelfReferralsInternalLink={get(
															this.props,
															'currentUser.company.allowSelfReferralsInternalLink'
														)}
														theme={theme}
														fontWeight={300}
														fontSize={11}
														height={240}
														minHeight={240}
														width="100%"
														minWidth={220}
														incompleteCareerProfile={
															careerProgress.hasAddedSkills.value === false ||
															careerProgress.hasAddedExperience.value === false
														}
														onCreateReferral={this.props.onCreateReferral}
													/>
												</List.Item>
											)}
										/>
									) : (
										this.renderUIStatusMessage(
											'No recommended jobs in your department.',
											companyUI
										)
									)}
								</div>
							</Col>
						</Row>
						<Row gutter={12} type="flex" style={{ marginBottom: 20 }}>
							<Col xs={8} md={4} lg={4} xl={4}>
								<div className="stat-box all">
									<div className="stats">
										<h4>{this.getJobCount(allJobMatches)}</h4>
										<p>Company Wide</p>
									</div>
								</div>
							</Col>
							<Col xs={16} md={20} lg={20} xl={20}>
								<div className="card-row recommended">
									{loadingJobMatches ? (
										this.customLoader('Looking for recommended jobs')
									) : allJobMatches && allJobMatches.length > 0 ? (
										<List
											dataSource={allJobMatchesDataSource}
											grid={{
												gutter: 12,
												xs: 1,
												sm: 1,
												md: 2,
												lg: 2,
												xl: 3,
												xxl: 4,
											}}
											className="job-card-wrap"
											pagination={{
												responsive: true,
												showLessItems: true,
												pageSize,
												hideOnSinglePage: true,
											}}
											renderItem={(jobMatch) => (
												<List.Item>
													<JobMatchCard
														handleCareerProfile={this.handleCareerProfile}
														allMultiLingualData={allMultiLingualData}
														currentUser={this.props.currentUser}
														jobMatch={jobMatch}
														matchedJobs={allJobMatches}
														notInterested={this.notInterested}
														allowSelfReferrals={get(
															this.props,
															'currentUser.company.allowSelfReferrals'
														)}
														allowSelfReferralsInternalLink={get(
															this.props,
															'currentUser.company.allowSelfReferralsInternalLink'
														)}
														theme={theme}
														fontWeight={300}
														fontSize={11}
														height={240}
														minHeight={240}
														width="100%"
														minWidth={220}
														incompleteCareerProfile={
															careerProgress.hasAddedSkills.value === false ||
															careerProgress.hasAddedExperience.value === false
														}
														onCreateReferral={this.props.onCreateReferral}
													/>
												</List.Item>
											)}
										/>
									) : (
										this.renderUIStatusMessage(
											'No recommended jobs.',
											companyUI
										)
									)}
								</div>
							</Col>
						</Row>
					</section>
					<Modal
						className={ModalStyles}
						open={visible}
						footer={null}
						onCancel={() => this.handleClose()}
					>
						<div>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
									marginBottom: '0.5rem',
								}}
							>
								<h1 style={{ marginBottom: 0 }} className={h1Style}>
									Background Image
								</h1>{' '}
								&nbsp;
								<span style={{ fontSize: '12px', color: COLORS.gray }}>
									(Optimal image size: 1500x300)
								</span>
							</div>
							<Upload
								showUploadList
								name="logo"
								listType="picture-card"
								className={
									// Hides upload button
									fileListInternalMobilityImage.length > 0 &&
									css({
										'& .ant-upload-select-picture-card': {
											display: 'none',
										},
									})
								}
								customRequest={
									this.internalMobilityImageRequest // To enable it to display in antD upload component through url // Custom request uploads the image to s3, sets to state, then filelist in render converts that logo
								}
								beforeUpload={beforeUpload}
								multiple={false}
								disabled={fileListInternalMobilityImage.length > 0}
								fileList={fileListInternalMobilityImage}
							>
								<PlusOutlined />
								<div className="ant-upload-text">Upload</div>
							</Upload>
						</div>

						{internalMobilityImage && (
							<Popconfirm
								title="Are you sure？"
								placement="right"
								icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
								onConfirm={async () => await this.removeInternalMobilityImage()}
							>
								<div
									style={{
										display: 'inline-flex',
										alignItems: 'center',
										cursor: 'pointer',
									}}
								>
									<DeleteOutlined />
									&nbsp;
									<span>Remove</span>
								</div>
							</Popconfirm>
						)}
					</Modal>
					<Modal
						className={ModalStylesMiddle}
						open={visibleFirst}
						footer={null}
						onCancel={() => this.handleCloseFirst()}
					>
						<div>
							<div style={{ marginBottom: 20 }}>
								<div className={SubTitleLower}>
									<FormItem style={{ width: 625 }}>
										{this.newHugeRteFirst()}
									</FormItem>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-evenly',
									marginTop: 20,
									gap: 10,
								}}
							>
								<ProgressButton
									controlled
									durationSuccess={3000}
									state={this.state.buttonStateFirst}
									onClick={() => this.addInternalMobilityFirst()}
								>
									{this.state.editFirst
										? ml('Update', currentUser, allMultiLingualData)
										: ml('Add Content', currentUser, allMultiLingualData)}
								</ProgressButton>

								<ProgressButton
									controlled
									durationSuccess={3000}
									state={this.state.buttonStateFirst}
									onClick={() =>
										this.deleteInternalMobilityResource(
											'contentFirst',
											this.clearEditorFirst
										)
									}
								>
									{ml('Delete', currentUser, allMultiLingualData)}
								</ProgressButton>
							</div>
						</div>
					</Modal>
					<Modal
						className={ModalStylesMiddle}
						open={visibleMiddle}
						footer={null}
						onCancel={() => this.handleCloseMiddle()}
					>
						<div>
							<div style={{ marginBottom: 20 }}>
								<div className={SubTitleLower}>
									<FormItem style={{ width: 625 }}>
										{this.newHugeRteMiddle()}
									</FormItem>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-evenly',
									marginTop: 20,
									gap: 10,
								}}
							>
								<ProgressButton
									controlled
									durationSuccess={3000}
									state={this.state.buttonStateMiddle}
									onClick={() => this.addInternalMobilityMiddle()}
								>
									{this.state.editMiddle
										? ml('Update', currentUser, allMultiLingualData)
										: ml('Add Content', currentUser, allMultiLingualData)}
								</ProgressButton>

								<ProgressButton
									controlled
									durationSuccess={3000}
									state={this.state.buttonStateMiddle}
									onClick={() =>
										this.deleteInternalMobilityResource(
											'contentMiddle',
											this.clearEditorMiddle
										)
									}
								>
									{ml('Delete', currentUser, allMultiLingualData)}
								</ProgressButton>
							</div>
						</div>
					</Modal>
					<Modal
						className={ModalStylesMiddle}
						open={visibleLast}
						footer={null}
						onCancel={() => this.handleCloseLast()}
					>
						<div>
							<div style={{ marginBottom: 20 }}>
								<div className={SubTitleLower}>
									<FormItem style={{ width: 625 }}>
										{this.newHugeRteLast()}
									</FormItem>
								</div>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'space-evenly',
									marginTop: 20,
									gap: 10,
								}}
							>
								<ProgressButton
									controlled
									durationSuccess={3000}
									state={this.state.buttonStateLast}
									onClick={() => this.addInternalMobilityLast()}
								>
									{this.state.editLast
										? ml('Update', currentUser, allMultiLingualData)
										: ml('Add Content', currentUser, allMultiLingualData)}
								</ProgressButton>

								<ProgressButton
									controlled
									durationSuccess={3000}
									state={this.state.buttonStateLast}
									onClick={() =>
										this.deleteInternalMobilityResource(
											'contentLast',
											this.clearEditorLast
										)
									}
								>
									{ml('Delete', currentUser, allMultiLingualData)}
								</ProgressButton>
							</div>
						</div>
					</Modal>
				</main>
			</>
		);
	}
}

export default withApollo(Form.create()(InternalComponent));
