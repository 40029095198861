import { connect } from 'react-redux';
import { withGetUserNotifications } from 'src/_shared/api/components/users/';
import { userActions } from 'src/actions.js';
import { compose } from 'src/_shared/services/utils.js';
import WebNotificationsComponent from 'src/web-notifications/web-notification-dropdown/WebNotificationsComponent.jsx';

const mapStateToProperties = (state) => {
	return {
		limit: 10,
	};
};

const mapDispatchToProperties = (dispatch) => {
	return {
		updateCurrentUserLastNotificationCheck(lastNotificationCheck) {
			dispatch(
				userActions.updateUserLastNotificationCheck(lastNotificationCheck)
			);
		},
	};
};

const WebNotificationsWithApi = compose(withGetUserNotifications)(
	WebNotificationsComponent
);

export const WebNotifications = connect(
	mapStateToProperties,
	mapDispatchToProperties
)(WebNotificationsWithApi);
