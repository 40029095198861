import React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { getFilteredNotifications } from 'src/_shared/services/utils';

export const QueryWebNotificationsByUserIdDateCreatedIndex = gql`query QueryWebNotificationsByUserIdDateCreatedIndex(
  $userId: ID!
  $after: String
) {
  queryWebNotificationsByUserIdDateCreatedIndex(
    userId: $userId
    dateCreated: null
    first: 10
    after: $after
  ) {
    items {
      id
      userId
      user {
        id
        companyId
        company {
          id
          name
        }
        firstName
        lastName
        avatar {
          bucket
          region
          key
        }
      }
      status
      type
      dateCreated
      companyId
      referralId
      referral {
        contactId
        contact {
          id
          firstName
          lastName
        }
        userId
        user {
          id
          firstName
          lastName
          avatar {
            key
          }
        }
      }
      referralType
      referralSource
      referralRequestedStatus
      referralDevice
      requestingUserId
      requestingUser {
        id
        firstName
        lastName
        avatar {
          key
        }
      }
      questionsData
      message
      jobId
      job {
        id
        title
        departmentId
        department {
          id
          name
        }
        location
        referralBonus
        subCompanyId
        subCompany {
          id
          name
        }
      }
      matches
      contactId
      contact {
        id
        firstName
        lastName
        emailAddress
        socialMediaAccounts
      }
    }
    nextToken
  }
}
`;

/**
 * Higher Order Component that provides notification data with pagination
 * @param {React.ComponentType} WrappedComponent - Component to wrap
 * @returns {React.ComponentType} - Wrapped component with notification data
 */
const withWebNotificationData = (WrappedComponent) => {
  return class WithWebNotificationData extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoadingMore: false,
        allFetched: false,
        currentPage: 1,
        allNotifications: [],
        filteredNotifications: [],
        currentNotifications: [],
        initialLoaded: false
      };
      
      // Additional tracking fields
      this.lastProcessedDataLength = 0;
      this._processingScheduled = false;
      this._dataProcessTimeoutId = null;
      this._loadMoreTimeoutId = null;
      this._mounted = false;
      this._pageChangeRequiresMoreData = false;
      this._cacheErrors = 0;
    }
    
    componentDidMount() {
      this._mounted = true;
    }
    
    componentWillUnmount() {
      this._mounted = false;
      // Clean up any pending timeouts
      if (this._dataProcessTimeoutId) {
        clearTimeout(this._dataProcessTimeoutId);
      }
      if (this._loadMoreTimeoutId) {
        clearTimeout(this._loadMoreTimeoutId);
      }
    }

    componentDidUpdate(prevProps, prevState) {
      // Make sure we update current notifications when state changes
      if (prevState.currentPage !== this.state.currentPage) {
        this.updateCurrentNotifications();
        this._pageChangeRequiresMoreData = true;
        this.checkIfPageNeedsMoreData();
      } else if (prevState.filteredNotifications !== this.state.filteredNotifications) {
        this.updateCurrentNotifications();
        if (this._pageChangeRequiresMoreData) {
          this.checkIfPageNeedsMoreData();
        }
      }
    }

    // Safe setState that only works if component is mounted
    safeSetState = (stateUpdate, callback = null) => {
      if (this._mounted) {
        this.setState(stateUpdate, callback);
      }
    }

    // Check if the current page needs more data
    checkIfPageNeedsMoreData = () => {
      const { currentPage } = this.state;
      const { pageSize = 10 } = this.props;
      const { filteredNotifications } = this.state;
      
      if (this.state.allFetched) {
        this._pageChangeRequiresMoreData = false;
        return;
      }
      
      const requiredItems = currentPage * pageSize;
      
      if (filteredNotifications.length < requiredItems && !this.state.isLoadingMore) {
        this._pageChangeRequiresMoreData = true;
      } else {
        this._pageChangeRequiresMoreData = false;
      }
    }

    updateCurrentNotifications = () => {
      const { currentPage } = this.state;
      const { pageSize = 10 } = this.props;
      const { filteredNotifications } = this.state;
      
      // Calculate what to display based on current page
      const startIndex = (currentPage - 1) * pageSize;
      const endIndex = Math.min(startIndex + pageSize, filteredNotifications.length);
      const currentItems = filteredNotifications.slice(startIndex, endIndex);
      
      this.safeSetState({
        currentNotifications: currentItems
      });
    }

    loadMoreData = async (fetchMore, nextToken) => {
      const { currentUser } = this.props;
      
      if (this.state.isLoadingMore || !nextToken || this.state.allFetched) {
        return false;
      }
      
      this.safeSetState({ isLoadingMore: true });
      
      try {
        
        await fetchMore({
          variables: {
            userId: currentUser.id,
            after: nextToken
          },
          updateQuery: (prev, { fetchMoreResult }) => {
            if (!fetchMoreResult) return prev;
            
            const newNextToken = fetchMoreResult.queryWebNotificationsByUserIdDateCreatedIndex.nextToken;
            
            if (!newNextToken && this._mounted) {
              setTimeout(() => {
                this.safeSetState({ allFetched: true });
              }, 0);
            }
            
            try {
              const combinedItems = this.safelyMergeItems(
                prev.queryWebNotificationsByUserIdDateCreatedIndex.items || [],
                fetchMoreResult.queryWebNotificationsByUserIdDateCreatedIndex.items || []
              );
              
              return {
                queryWebNotificationsByUserIdDateCreatedIndex: {
                  __typename: prev.queryWebNotificationsByUserIdDateCreatedIndex.__typename,
                  items: combinedItems,
                  nextToken: newNextToken
                }
              };
            } catch (err) {
              console.error("Error merging items:", err);
              this._cacheErrors++;
              
              // If we hit too many cache errors, mark as all fetched to prevent infinite loops
              if (this._cacheErrors > 3) {
                setTimeout(() => {
                  this.safeSetState({ allFetched: true });
                }, 0);
              }
              
              return {
                queryWebNotificationsByUserIdDateCreatedIndex: {
                  __typename: prev.queryWebNotificationsByUserIdDateCreatedIndex.__typename,
                  items: prev.queryWebNotificationsByUserIdDateCreatedIndex.items,
                  nextToken: newNextToken
                }
              };
            }
          }
        });
        
        return true;
      } catch (error) {
        console.error("Error fetching more notifications:", error);
        
        this._cacheErrors++;
        
        // If we hit too many cache errors, mark as all fetched
        if (this._cacheErrors > 3) {
          this.safeSetState({ allFetched: true });
        } else {
          return true;
        }
        
        return false;
      } finally {
        if (this._mounted) {
          this.safeSetState({ isLoadingMore: false });
        }
      }
    };

    safelyMergeItems = (prevItems, newItems) => {
      // Create a map of existing IDs to prevent duplicates
      const idMap = new Map();
      prevItems.forEach(item => {
        if (item.id) {
          idMap.set(item.id, true);
        }
      });
      
      // Filter out any items that might cause conflicts
      const filteredNewItems = newItems.filter(item => {
        return item.id && !idMap.get(item.id);
      });
      
      // Return combined array
      return [...prevItems, ...filteredNewItems];
    };

    processQueryData = (data) => {
      if (!data || !data.queryWebNotificationsByUserIdDateCreatedIndex) return;
      
      const allItems = data.queryWebNotificationsByUserIdDateCreatedIndex.items || [];
      const filtered = getFilteredNotifications(allItems);
      
      // Use setTimeout to defer state update until after the render phase
      this._dataProcessTimeoutId = setTimeout(() => {
        if (this._mounted) {
          this.safeSetState({
            allNotifications: allItems,
            filteredNotifications: filtered,
            initialLoaded: true
          });
        }
        this._dataProcessTimeoutId = null;
      }, 0);
      
      return {
        hasMore: !this.state.allFetched && !!data.queryWebNotificationsByUserIdDateCreatedIndex.nextToken,
        nextToken: data.queryWebNotificationsByUserIdDateCreatedIndex.nextToken
      };
    };

    // Safely check if we need to load more data after a render
    checkAndLoadMore = (data, fetchMore) => {
      if (!data || !this._mounted || this.state.isLoadingMore || this.state.allFetched) return;
      
      const { currentPage } = this.state;
      const { pageSize = 10 } = this.props;
      const requiredItems = currentPage * pageSize;
      
      const filtered = getFilteredNotifications(data.queryWebNotificationsByUserIdDateCreatedIndex.items || []);
      const nextToken = data.queryWebNotificationsByUserIdDateCreatedIndex.nextToken;
      
      if (filtered.length < requiredItems && nextToken) {
        this._loadMoreTimeoutId = setTimeout(() => {
          if (this._mounted && !this.state.isLoadingMore) {
            this.loadMoreData(fetchMore, nextToken);
          }
          this._loadMoreTimeoutId = null;
        }, 0);
      } else {
        // We have enough data for the current page
        this._pageChangeRequiresMoreData = false;
      }
    };

    handlePageChange = (page) => {
      this.safeSetState({ 
        currentPage: page 
      });
    };

    render() {
      const { currentUser, pageSize = 10, ...restProps } = this.props;
      const { 
        currentPage,
        allNotifications,
        filteredNotifications,
        currentNotifications,
        isLoadingMore,
        allFetched,
        initialLoaded
      } = this.state;
      
      return (
        <Query
          query={QueryWebNotificationsByUserIdDateCreatedIndex}
          variables={{ userId: currentUser.id }}
          notifyOnNetworkStatusChange={true}
          fetchPolicy="cache-and-network"
          errorPolicy="all"
        >
          {({ data, loading, error, fetchMore, networkStatus, refetch }) => {
            const isInitialLoading = loading && !initialLoaded;
            const isNetworkLoading = networkStatus === 1 && !initialLoaded;
            
            // Apollo v2 provides networkStatus - 3 means "fetchMore in progress"
            const isLoading = isInitialLoading || isNetworkLoading || networkStatus === 3 || isLoadingMore;
            
            const dataLength = data?.queryWebNotificationsByUserIdDateCreatedIndex?.items?.length || 0;
            const isNewData = data && 
                             data.queryWebNotificationsByUserIdDateCreatedIndex && 
                             (!this.lastProcessedDataLength || 
                              this.lastProcessedDataLength !== dataLength);
              
            // Schedule data processing outside the render cycle
            if (isNewData && !this._processingScheduled && this._mounted) {
              this._processingScheduled = true;
              
              setTimeout(() => {
                if (this._mounted) {
                  this.processQueryData(data);
                  
                  this.checkAndLoadMore(data, fetchMore);
                  
                  this.lastProcessedDataLength = dataLength;
                }
                
                this._processingScheduled = false;
              }, 0);
            }
            
            if (this._pageChangeRequiresMoreData && 
                !this._processingScheduled && 
                !isLoading && 
                data?.queryWebNotificationsByUserIdDateCreatedIndex?.nextToken) {
              
              // Schedule loading more data if we need it
              setTimeout(() => {
                if (this._mounted && this._pageChangeRequiresMoreData) {
                  this.checkAndLoadMore(data, fetchMore);
                }
              }, 0);
            }
            
            const safeCurrentNotifications = currentNotifications || [];
            const safeFilteredNotifications = filteredNotifications || [];
            
            // Pagination configuration for Ant Design
            const pagination = {
              total: safeFilteredNotifications.length,
              current: currentPage,
              pageSize: pageSize,
              onChange: this.handlePageChange,
              showTotal(total, range) {
                return `${range[0]}-${range[1]} of ${total} ${allFetched ? '' : 'most recent'} notifications`;
              },
              showSizeChanger: false,
            };

            const enhancedProps = {
              ...restProps,
              currentUser,
              notificationData: {
                allNotifications,
                filteredNotifications: safeFilteredNotifications,
                currentNotifications: safeCurrentNotifications,
                pagination,
                loading: isLoading,
                error,
                currentPage,
                handlePageChange: this.handlePageChange,
                allFetched,
                hasMore: !allFetched && 
                  data?.queryWebNotificationsByUserIdDateCreatedIndex?.nextToken,
                loadMoreData: () => {
                  if (data?.queryWebNotificationsByUserIdDateCreatedIndex?.nextToken) {
                    return this.loadMoreData(
                      fetchMore, 
                      data.queryWebNotificationsByUserIdDateCreatedIndex.nextToken
                    );
                  }
                  return Promise.resolve(false);
                },
                refetch
              }
            };

            return <WrappedComponent {...enhancedProps} />;
          }}
        </Query>
      );
    }
  };
};

export default withWebNotificationData;
