// src/components/BonusTable.jsx - Updated to use the enhanced actions
import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Button,
  Dropdown,
  Input,
  Popconfirm,
  Table,
  message,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import getSymbolFromCurrency from 'currency-symbol-map';
import dayjs from 'dayjs';
import get from 'lodash/get';
import { Link } from 'react-router-dom';
import JobTitleComponent from 'src/_shared/components/JobTitleComponent.jsx';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import {
  formatDate,
  initialCaps,
  mapReferralStatus,
  ml,
} from 'src/_shared/services/utils.js';
import { BONUS_STATUS, USER_ROLES } from '../constants';
import { popoverStyles } from './referralsTableStyles.js';
import { updateBonusStatus, fetchEligibilityResults } from 'src/_shared/referral/referralActions';
import BonusDetailsModal from './BonusTableDetailsModalComponent';

const BonusTable = (props) => {
  const {
    bonuses,
    filterByReferralId,
    filteredReferralId,
    company,
    theme,
    setExportData,
    sortByAlph,
    currentUser,
    allMultiLingualData,
    // Redux actions and state
    updateBonusStatus,
    fetchEligibilityResults,
    fetchingEligibility
  } = props;

   const [tableBonuses, setTableBonuses] = useState(bonuses);
   const [selectedBonusId, setSelectedBonusId] = useState(null);
   const [modalVisible, setModalVisible] = useState(false);
   const [visibility, setVisibility] = useState({});
   const [reasonForChange, setReasonForChange] = useState('');
   const [awaitingResponse, setAwaitingResponse] = useState(false);
   const [modalLoading, setModalLoading] = useState(false);

  const headers = useMemo(() => ({
    name: ml('Name', currentUser, allMultiLingualData),
    recipientType: ml('Recipient Type', currentUser, allMultiLingualData),
    job: ml('Job', currentUser, allMultiLingualData),
    referral: ml('Referral', currentUser, allMultiLingualData),
    bonusAmount: ml('Bonus Amount', currentUser, allMultiLingualData),
    elligibleDate: ml('Eligible Date', currentUser, allMultiLingualData),
    payment: ml('Payment', currentUser, allMultiLingualData),
    status: ml('Status', currentUser, allMultiLingualData),
    details: ml('Details', currentUser, allMultiLingualData),
  }), [currentUser, allMultiLingualData]);

  const ml_of = useMemo(() => ml('of', currentUser, allMultiLingualData), 
    [currentUser, allMultiLingualData]);

    const handleOpenDetailsModal = useCallback((bonus) => {
      setSelectedBonusId(bonus.id);
      
      if (bonus.referralId && !bonus.referral?.eligibilityResults) {
        setModalLoading(true);
        fetchEligibilityResults(bonus.referralId, bonus.id)
          .then(() => {
            setModalLoading(false);
            setModalVisible(true);
          })
          .catch(error => {
            console.error('Error fetching eligibility results:', error);
            setModalLoading(false);
            setModalVisible(true);
          });
      } else {
        setModalVisible(true);
      }
    }, [fetchEligibilityResults]);

  const selectedBonus = useMemo(() => {
    return tableBonuses.find(bonus => bonus.id === selectedBonusId) || null;
  }, [tableBonuses, selectedBonusId]);

  const handleCloseModal = useCallback(() => {
    setModalVisible(false);
    setSelectedBonusId(null);
  }, []);

  const isPromptDisabled = (bonusStatus, targetKey) => {
    if (BONUS_STATUS.hasOwnProperty(bonusStatus)) {
      return BONUS_STATUS[bonusStatus] === BONUS_STATUS[targetKey];
    }
    return false;
  };

  useEffect(() => {
    if (filteredReferralId) {
      const filtered = bonuses.filter(
        (bonus) => bonus.referralId === filteredReferralId
      );
      setTableBonuses(filtered);
      getExportData(filtered);
    } else {
      setTableBonuses(bonuses);
      getExportData(bonuses);
    }
  }, [bonuses, filteredReferralId]);

  const getExportData = useCallback(async (data) => {
    if (!data || data.length === 0) return;
    
    const languageCode = currentUser.languageCode ?? 'US';
    const newData = [];
    
    for (const bonus of data) {
      newData.push(bonus);
    }
  
    const detailHeaders = {
      currency: ml('Currency', currentUser, allMultiLingualData),
      title: ml('Referring Employee Title', currentUser, allMultiLingualData),
      companyNumber: ml(
        'Referring Employee Company Number',
        currentUser,
        allMultiLingualData
      ),
      employeeNumber: ml(
        'Referring Employee Number',
        currentUser,
        allMultiLingualData
      ),
      referredNumber: ml(
        'Referred Employee Number',
        currentUser,
        allMultiLingualData
      ),
      status: ml('Referring Employee Status', currentUser, allMultiLingualData),
      hiredDate: ml(
        `${mapReferralStatus('hired', currentUser.company)} Date`,
        currentUser,
        allMultiLingualData
      ),
      startDate: ml('Start Date', currentUser, allMultiLingualData),
      eligibleDate: ml('Eligible Date', currentUser, allMultiLingualData),
      paidDate: ml('Paid Date', currentUser, allMultiLingualData),
      logs: ml('Logs', currentUser, allMultiLingualData),
    };
    const ml_active = ml('Active', currentUser, allMultiLingualData);
    const ml_inactive = ml('Inactive', currentUser, allMultiLingualData);
  
    const includeCompanyNumber = newData.some(
      (record) => record.user?.accountClaim?.companyNumber
    );
    const includeEmployeeNumber = newData.some(
      (record) => record.user?.accountClaim?.employeeId
    );
    const includeReferredNumber = newData.some(
      (record) => record.contact?.accountClaim?.employeeId
    );
  
    const parseName = (record) => {
      const userType = record.recipientType === 'employee' ? 'user' : 'contact';
      return `${record[userType]?.firstName ?? ''} ${record[userType]?.lastName ?? ''}`;
    };
  
    const parseBonus = (record) => {
      const formattedAmount =
        record.amountDue?.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',') ??
        '';
      const selectedCurrency =
        record.currency ?? record.user?.userGroup?.currency ?? 'USD';
      return `${selectedCurrency}${formattedAmount}`;
    };
  
    const parseCurrency = (record) => {
      const currency = record.user?.userGroup?.currency ?? 'USD';
      const symbol = getSymbolFromCurrency(currency);
      return `${symbol} ${currency}`;
    };
  
    const exportData = newData.map((record) => {
      const row = {
        [headers.name]: parseName(record),
        [headers.recipientType]: initialCaps(record.recipientType ?? ''),
        [headers.job]: `${record.job?.title}`,
        [headers.referral]: `${record.contact?.firstName ?? ''} ${record.contact?.lastName ?? ''}`,
        [headers.bonusAmount]: parseBonus(record),
        [headers.elligibleDate]: formatDate(
          record.earnedDate,
          currentUser.languageCode,
          currentUser.dateFormat
        ),
        [headers.payment]: record.payment?.replace('of', ml_of),
        [headers.status]: `${ml(BONUS_STATUS[record.bonusStatus], currentUser, company)}`,
        [detailHeaders.currency]: parseCurrency(record),
        [detailHeaders.hiredDate]: formatDate(
          record.hiredDate,
          languageCode,
          allMultiLingualData
        ),
        [detailHeaders.startDate]: formatDate(
          record.startDate,
          languageCode,
          allMultiLingualData
        ),
        [detailHeaders.eligibleDate]: formatDate(
          record.earnedDate,
          languageCode,
          allMultiLingualData
        ),
        [detailHeaders.paidDate]: formatDate(
          record.paidDate,
          languageCode,
          allMultiLingualData
        ),
      };
  
      if (includeCompanyNumber) {
        row[detailHeaders.companyNumber] =
          record.user?.accountClaim?.companyNumber ?? '';
      }
  
      if (includeEmployeeNumber) {
        row[detailHeaders.employeeNumber] =
          record.user?.accountClaim?.employeeId ?? '';
      }
  
      if (includeReferredNumber) {
        row[detailHeaders.referredNumber] =
          record?.contact?.accountClaim?.employeeId ?? '';
      }
  
      if (record.referralId) {
        try {
          const referringUserData = record?.referral?.user || {};
          row[detailHeaders.title] = `${referringUserData?.title ?? ''}`;
          row[detailHeaders.status] = referringUserData?.active ? ml_active : ml_inactive;
          row[detailHeaders.logs] = record?.referral?.eligibilityResults ?? 'Unavailable';
        } catch {
          row[detailHeaders.logs] = 'Unavailable';
        }
      }
  
      return row;
    });
  
    setExportData(exportData);
  }, [currentUser, headers, ml_of, company, allMultiLingualData, setExportData]);

  useEffect(() => {
    if (selectedBonusId && !fetchingEligibility && modalLoading) {
      setModalLoading(false);
      setModalVisible(true);
    }
  }, [selectedBonusId, fetchingEligibility, modalLoading]);

  const getPaymentLink = useCallback((payment, referralId) => {
    const payString = payment === null ? '' : payment;
    const splitLink = payString.split('');
    const lastChar = splitLink.pop();
    const label = splitLink.join('').replace('of', ml_of);
    
    return (
      <div>
        {label}{' '}
        <Button
          type="link"
          className="btn-link px-1"
          style={{ height: 'auto' }}
          onClick={() => filterByReferralId(referralId)}
        >
          {lastChar}
        </Button>
      </div>
    );
  }, [ml_of, filterByReferralId]);

  const handleUpdateBonusStatus = useCallback((status, id, referralId, payment) => {
    setVisibility(prev => ({
      ...prev,
      [id]: false,
    }));

    const bonusInput = {
      id,
      bonusStatus: status,
      paidDate: status === 'paid' ? new Date().toISOString() : null,
    };

    if (!reasonForChange.trim() && referralId) {
      message.error(
        ml('Reason for change required', currentUser, allMultiLingualData)
      );
      return;
    }
    const logs = [];
    const logMessage = {
      date: dayjs().toISOString(),
      changeLog:`${currentUser.emailAddress} updated the status on payment "${payment}" with the reason "${reasonForChange}`,
    };
    logs.push(logMessage);
    setAwaitingResponse(true);
    updateBonusStatus(bonusInput, referralId, logs)
      .then(() => {
        setAwaitingResponse(false);
        setReasonForChange('');
      })
      .catch((error) => {
        console.error('Error updating bonus status:', error);
        setAwaitingResponse(false);
      });
  }, [reasonForChange, currentUser, allMultiLingualData, updateBonusStatus]);

  const handleReasonForChange = useCallback((e) => {
    setReasonForChange(e.target.value);
  }, []);

  const handleTableChange = useCallback(async (event) => {
    if (event.action === 'sort') {
      await getExportData(event.currentDataSource);
    }
  }, [getExportData]);

  const handleVisibleChange = useCallback((key, flag) => {
    setVisibility(prev => ({
      ...prev,
      [key]: flag,
    }));
    
    if (!flag) {
      setReasonForChange('');
    }
  }, []);

  const conditionalStatusStyle = useCallback((key) => {
    const styles = {
      pending: { color: 'var(--tan-hide)', fontWeight: 600 },
      ineligible: { color: 'var(--sunset-orange)', fontWeight: 600 },
      earned: { color: 'var(--fern)', fontWeight: 600 },
      paid: { color: 'var(--forest-green)', fontWeight: 600 },
      needsReview: { color: 'var(--mellow-yellow)', fontWeight: 600 },
    };
    
    return styles[key] || {};
  }, []);

  const columns = useMemo(() => [
    {
      title: headers.name,
      key: 'name',
      width: '15%',
      showSorterTooltip: false,
      sorter(a, b) {
        const aFirstName =
          get(a, 'recipientType') === 'employee'
            ? get(a, 'user.firstName')
            : get(a, 'contact.firstName');
        const aLastName =
          get(a, 'recipientType') === 'employee'
            ? get(a, 'user.lastName')
            : get(a, 'contact.lastName');
        const bFirstName =
          get(b, 'recipientType') === 'employee'
            ? get(b, 'user.firstName')
            : get(b, 'contact.firstName');
        const bLastName =
          get(b, 'recipientType') === 'employee'
            ? get(b, 'user.lastName')
            : get(b, 'contact.lastName');
        return sortByAlph(aFirstName + aLastName, bFirstName + bLastName);
      },
      render(record, data) {
        const firstName =
          get(data, 'recipientType') === 'employee'
            ? get(data, 'user.firstName')
            : get(data, 'contact.firstName');
        const lastName =
          get(data, 'recipientType') === 'employee'
            ? get(data, 'user.lastName')
            : get(data, 'contact.lastName');
        const URL =
          get(data, 'recipientType') === 'employee'
            ? `/employees/${get(data, 'userId')}`
            : `/referrals/${get(data, 'referralId')}`;
        return (
          <Link to={URL} className="table-link">
            {firstName} {lastName}
          </Link>
        );
      },
    },
    {
      title: headers.recipientType,
      dataIndex: 'recipientType',
      key: 'recipientType',
      showSorterTooltip: false,
      sorter: (a, b) => sortByAlph(a.recipientType, b.recipientType),
      render(type) {
        if (type && typeof type === 'string') {
          const value = type.split('');
          value.splice(0, 1, value[0].toUpperCase());
          return value.join('');
        }
      },
    },
    {
      title: headers.job,
      dataIndex: 'job',
      key: 'job',
      width: '16%',
      showSorterTooltip: false,
      sorter: (a, b) => sortByAlph(get(a, 'job.title'), get(b, 'job.title')),
      render: (job) =>
        job === null ? null : (
          <Link className="table-link" to={`/jobs/${job.id}`}>
            <JobTitleComponent
              jobTitle={job.title}
              currentUser={currentUser}
            />
          </Link>
        ),
    },
    {
      title: headers.referral,
      key: 'referral',
      width: '11%',
      showSorterTooltip: false,
      sorter(a, b) {
        const aFirstName = get(a, 'contact.firstName');
        const aLastName = get(a, 'contact.lastName');
        const bFirstName = get(b, 'contact.firstName');
        const bLastName = get(b, 'contact.lastName');
        return sortByAlph(aFirstName + aLastName, bFirstName + bLastName);
      },
      render(bonus) {
        if (bonus.referral !== null && bonus.contact !== null) {
          return (
            <Link
              className="table-link"
              to={`/referrals/${bonus.referralId}`}
            >
              {bonus.contact.firstName + ' ' + bonus.contact.lastName}
            </Link>
          );
        }

        if (!bonus.referral && bonus.contact !== null) {
          return (
            <>{bonus.contact.firstName + ' ' + bonus.contact.lastName}</>
          );
        }
      },
    },
    {
      title: headers.bonusAmount,
      dataIndex: 'amountDue',
      key: 'amountDue',
      width: '10%',
      showSorterTooltip: false,
      sorter(a, b) {
        return sortByAlph(a.amountDue, b.amountDue);
      },
      render(amount, row) {
        const amountValue =
          amount > 0
            ? amount.toString().replaceAll(/\B(?=(\d{3})+(?!\d))/g, ',')
            : amount;
        let userGroupCurrency = get(row, 'user.userGroup.currency');
        userGroupCurrency = userGroupCurrency ? userGroupCurrency : 'USD';
        let currency = get(row, 'currency');
        currency = currency ? currency : userGroupCurrency;
        const symbol = getSymbolFromCurrency(currency);
        return (
          <span className="text-green">
            {symbol}
            {amountValue}
          </span>
        );
      },
    },
    {
      title: headers.elligibleDate,
      dataIndex: 'earnedDate',
      key: 'earnedDate',
      width: '10%',
      showSorterTooltip: false,
      sorter: (a, b) => sortByAlph(dayjs(b.earnedDate), dayjs(a.earnedDate)),
      render: (earnedDate) =>
        formatDate(
          earnedDate,
          currentUser.languageCode,
          currentUser.dateFormat
        ),
    },
    {
      title: headers.payment,
      dataIndex: 'payment',
      key: 'payment',
      width: '9%',
      render: (payment, data) => (
        <>{getPaymentLink(payment, get(data, 'referralId'))}</>
      ),
    },
    {
      title: headers.status,
      dataIndex: 'bonusStatus',
      key: 'bonusStatus',
      showSorterTooltip: false,
      sorter: (a, b) => sortByAlph(a.bonusStatus, b.bonusStatus),
      render: (bonusStatus, data) => {
        const managerBonuses = currentUser.company?.disableManagerPermissionsByType
          ? JSON.parse(currentUser.company.disableManagerPermissionsByType).managerBonuses
          : 'hidden';
        const isManagerPermissionDisabled = managerBonuses !== 'edit';
        const displayAS = get(currentUser, 'displayAs');
        const isStatusDropdownDisabled = Boolean(
          isManagerPermissionDisabled && displayAS === USER_ROLES.MANAGER
        );
    
        const menuItems = Object.keys(BONUS_STATUS).map((key) => ({
          key,
          label: (
            <div onClick={(e) => e.stopPropagation()}>
              <Popconfirm
                key={key}
                title={
                  <>
                    <strong>
                      {ml('Confirm Updates?', currentUser, allMultiLingualData)}
                    </strong>
                    {data?.referralId && (
                      <>
                        <div
                          style={{
                            marginTop: '12px',
                            marginLeft: '-22px',
                          }}
                        >
                          <strong>
                            <span style={{ color: 'red' }}>* </span>
                            {ml('Reason for Change', currentUser, allMultiLingualData)}:
                          </strong>
                        </div>
                        <Input.TextArea
                          value={reasonForChange}
                          className="custom-input"
                          placeholder={
                            ml('Provide a reason for change', currentUser, allMultiLingualData) +
                            ' (' +
                            ml('required', currentUser, allMultiLingualData) +
                            ')'
                          }
                          style={{
                            width: '168px',
                            maxWidth: '168px',
                            marginTop: '2px',
                            marginLeft: '-22px',
                            resize: 'none',
                          }}
                          onChange={handleReasonForChange}
                          onClick={(e) => e.stopPropagation()}
                        />
                      </>
                    )}
                  </>
                }
                overlayClassName={popoverStyles}
                placement="top"
                okText={ml('Yes', currentUser, allMultiLingualData)}
                cancelText={ml('No', currentUser, allMultiLingualData)}
                zIndex={1071}
                disabled={isPromptDisabled(bonusStatus, key)}
                onConfirm={() =>
                  handleUpdateBonusStatus(
                    key,
                    data.id,
                    get(data, 'referralId'),
                    data.payment
                  )
                }
              >
                {ml(BONUS_STATUS[key], currentUser, allMultiLingualData)}
              </Popconfirm>
            </div>
          ),
          style: conditionalStatusStyle(key),
        }));
    
        return (
          <Dropdown
            key={data.id}
            trigger={['click']}
            style={{ marginRight: 5 }}
            open={visibility[data.id]}
            menu={{ items: menuItems }}
            disabled={isStatusDropdownDisabled}
            onOpenChange={(flag) => handleVisibleChange(data.id, flag)}
          >
            <Button type="link">
              <span style={conditionalStatusStyle(bonusStatus)}>
                {ml(BONUS_STATUS[bonusStatus], currentUser, allMultiLingualData)}{' '}
              </span>
              <DownOutlined />
            </Button>
          </Dropdown>
        );
      },
    },
    {
      title: headers.details,
      key: 'details',
      render: (bonus) => {
        return (
          <Button
            type="primary"
            size="small"
            onClick={() => handleOpenDetailsModal(bonus)}
          >
            Details
          </Button>
        );
      },
    },
  ], [
    headers, 
    sortByAlph, 
    currentUser, 
    allMultiLingualData,
    getPaymentLink,
    conditionalStatusStyle,
    visibility,
    reasonForChange,
    handleReasonForChange,
    handleVisibleChange,
    handleUpdateBonusStatus,
	  handleOpenDetailsModal
  ]);

  const locale = {
    emptyText: <Spinner company={company} />,
  };

  return (
    <>
      <Table
        pagination={{ pageSize: 25, showSizeChanger: false }}
        rowKey={(record) => record.id}
        dataSource={tableBonuses}
        columns={columns}
        locale={locale}
        scroll={{ x: 656 }}
        onChange={(_pagination, _filters, _sorter, extra) =>
          handleTableChange(extra)
        }
      />

      {modalLoading && (
        <Spinner company={company} />
      )}

      {/* Only render the modal when it should be visible and we have a selected bonus */}
      {modalVisible && selectedBonus && (
        <BonusDetailsModal
          bonus={selectedBonus}
          theme={theme}
          currentUser={currentUser}
          awaitingResponse={awaitingResponse}
          allMultiLingualData={allMultiLingualData}
          onClose={handleCloseModal}
          fetchingEligibility={fetchingEligibility}
          fetchEligibilityResults={fetchEligibilityResults}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => {
  return ({
    fetchingEligibility: state.referrals.fetchingEligibility
})};

const mapDispatchToProps = {
  updateBonusStatus,
  fetchEligibilityResults
};

export default connect(mapStateToProps, mapDispatchToProps)(BonusTable);
