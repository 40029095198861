export const updateUser = `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      accountClaimId
      emailAddress
      enableJobNotifications
      id
      lastNotificationCheck
    }
  }
`;
