export const NOTIFICATIONS = {
	JOB_CREATED: 'jobCreated',
	REFERRAL_CREATED: 'referralCreated',
	REFERRAL_ACCEPTED: 'referralAccepted',
	REFERRAL_HIRED: 'referralHired',
	REFERRAL_REQUESTED: 'referralRequested',
	REFERRAL_MATCH: 'referralMatch',
	CONTACTS_IMPORTED: 'contactImported',
	JOB_SUMMARY_EMAIL: 'jobSummaryEmail',
	EMP_MESSAGE_CENTER: 'empMessageCenter',
	SOCIAL_MEDIA_SHARE: 'socialMediaShare',
	REFERRAL_SENT: 'referralSent',
	GENERAL_REFERRAL: 'generalReferral',
	POINTS_REFERRAL_ACCEPTED: 'pointsReferralAccepted',
	POINTS_REFERRAL_INTERVIEWING: 'pointsReferralInterviewing',
	POINTS_REFERRAL_HIRED: 'pointsReferralHired',
};
