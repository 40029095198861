export const queryReferralEligibilityResults = `
query GetReferral($id: ID!) {
  getReferral(id: $id) {
    id
    userId
    user {
      id
      active
      title
      userGroupId
      userGroup {
        id
        currency
      }
    }
    eligibilityResults
  }
}
`;
