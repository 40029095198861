import { Button, Dropdown } from 'antd';
import { usePapaParse } from 'react-papaparse';
import { WebIcon } from '../index.js';

export const ExportSelector = ({ items, title }) => {
  // Process items to remove custom props before passing to the DOM
  const processedItems = items.map((item) => {
    // Destructure custom props and save them for later use
    const { companyName, reportType, ...rest } = item;

    // If onClick is a string indicating a CSV export, define a new onClick handler using the custom props
    if (rest.onClick === 'csv-export') {
      rest.onClick = () => {
        handleJsonToCSV(item.data, companyName, reportType);
      };
    }
    return rest;
  });

  const getFileName = (companyName, reportType) => {
    const regex = /[\s\\/.]/g;
    const formattedCompanyName = companyName.toLowerCase().replaceAll(regex, '-');
    return `${formattedCompanyName}-${reportType}-report`;
  };

  const { jsonToCSV } = usePapaParse();

  const handleDownload = (csvContent, companyName, reportType) => {
    // Create the file and URL
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);

    // Create a temporary link
    const link = document.createElement('a');
    link.href = url;
    link.download = getFileName(companyName, reportType);
    document.body.append(link);

    // Trigger the download
    link.click();

    // Clean up
    window.URL.revokeObjectURL(url);
    link.remove();
  };

  const handleJsonToCSV = (data, companyName, reportType) => {
    const jsonData = typeof data === 'string' ? data : JSON.stringify(data);
    const results = jsonToCSV(jsonData);
    handleDownload(results, companyName, reportType);
  };

  return (
    <Dropdown menu={{ items: processedItems }} trigger={['click']}>
      <Button className="export-selector">
        <div>{title}</div>
        <div className="export-arrow">
          <WebIcon color="var(--oslo-gray)" size={10} name="sort-down" />
        </div>
      </Button>
    </Dropdown>
  );
};
