import { createAction } from '../../helpers';
import { searchOpenSearchNetwork, lambda } from 'src/_shared/services/utils';
import { gql } from 'graphql-tag';
import { getApolloClient } from 'src/clientService';
import { queryReferralEligibilityResults } from 'src/_shared/api/graphql/custom/referrals/';
import { queryBonusUserDetails } from 'src/_shared/api/graphql/custom/bonuses/';
import { createBonus as createBonusMutation } from 'src/_shared/api/graphql/custom/bonuses/createBonusGraphql.js';
import { updateBonusAction } from 'src/_shared/api/graphql/custom/bonuses/updateBonusActionGraphql.js';
import { updateReferralStatus } from 'src/_shared/api/graphql/custom/referrals/updateReferralStatusGraphql';
import dayjs from 'dayjs';
import { parse } from 'src/_shared/services/utils';

export const FETCH_REFERRALS = 'Referral/FETCH_REFERRALS';
export const SET_CURRENT_REFERRAL = 'Referral/SET_CURRENT_REFERRAL';
export const UPDATE_REFERRAL = 'Referral/UPDATE_REFERRAL';
export const UPDATE_REFERRALS_NOT_HIRED = 'Referral/UPDATE_REFERRALS_NOT_HIRED';

// Action Types
export const REFERRAL_ACTIONS = {
  FETCH_BONUSES_REQUEST: 'FETCH_BONUSES_REQUEST',
  FETCH_BONUSES_SUCCESS: 'FETCH_BONUSES_SUCCESS',
  FETCH_BONUSES_FAILURE: 'FETCH_BONUSES_FAILURE',
  
  FETCH_BONUS_COUNTS_SUCCESS: 'FETCH_BONUS_COUNTS_SUCCESS',
  
  UPDATE_BONUS_REQUEST: 'UPDATE_BONUS_REQUEST',
  UPDATE_BONUS_SUCCESS: 'UPDATE_BONUS_SUCCESS',
  UPDATE_BONUS_FAILURE: 'UPDATE_BONUS_FAILURE',
  
  UPDATE_REFERRAL_REQUEST: 'UPDATE_REFERRAL_REQUEST',
  UPDATE_REFERRAL_SUCCESS: 'UPDATE_REFERRAL_SUCCESS',
  UPDATE_REFERRAL_FAILURE: 'UPDATE_REFERRAL_FAILURE',
  
  CREATE_BONUS_REQUEST: 'CREATE_BONUS_REQUEST',
  CREATE_BONUS_SUCCESS: 'CREATE_BONUS_SUCCESS',
  CREATE_BONUS_FAILURE: 'CREATE_BONUS_FAILURE',
  
  SET_FILTERED_STATUSES: 'SET_FILTERED_STATUSES',
  SET_FILTERED_DATES: 'SET_FILTERED_DATES',
  SET_FILTERED_USER_GROUPS: 'SET_FILTERED_USER_GROUPS',
  SET_FILTERED_REFERRAL_ID: 'SET_FILTERED_REFERRAL_ID',
  SET_SEARCH_QUERY: 'SET_SEARCH_QUERY',
  
  SET_EXPORT_DATA: 'SET_EXPORT_DATA',
  
  // New actions for eligibility results
  FETCH_ELIGIBILITY_RESULTS_REQUEST: 'FETCH_ELIGIBILITY_RESULTS_REQUEST',
  FETCH_ELIGIBILITY_RESULTS_SUCCESS: 'FETCH_ELIGIBILITY_RESULTS_SUCCESS',
  FETCH_ELIGIBILITY_RESULTS_FAILURE: 'FETCH_ELIGIBILITY_RESULTS_FAILURE',
  
  // Revalidation actions
  REVALIDATE_BONUS_REQUEST: 'REVALIDATE_BONUS_REQUEST',
  REVALIDATE_BONUS_SUCCESS: 'REVALIDATE_BONUS_SUCCESS',
  REVALIDATE_BONUS_FAILURE: 'REVALIDATE_BONUS_FAILURE',
};

// Action Creators
export const fetchBonuses = (parameters) => async (dispatch, getState) => {
  dispatch({ type: REFERRAL_ACTIONS.FETCH_BONUSES_REQUEST });
  
  try {
    const response = await searchOpenSearchNetwork(parameters, 'erin-bonuses');

    if (response) {
      dispatch({
        type: REFERRAL_ACTIONS.FETCH_BONUSES_SUCCESS, 
        payload: {
          bonuses: response.data || [],
          totalCount: response?.metadata?.showing?.totalItems || 0
        }
      });
      
      // Fetch bonus counts
      const { company } = getState().user.currentUser;
      const statusCountResponse = await lambda({
        endpoint: 'calculateBonusStatusCounts',
        variables: {
          companyId: company.id,
        },
      });
      
      if (statusCountResponse && statusCountResponse.message) {
        dispatch({
          type: REFERRAL_ACTIONS.FETCH_BONUS_COUNTS_SUCCESS,
          payload: statusCountResponse.message
        });
      }
      
      return response.data;
    }
  } catch (error) {
    dispatch({ 
      type: REFERRAL_ACTIONS.FETCH_BONUSES_FAILURE, 
      payload: error.message 
    });
  }
};

// New action to fetch eligibility results for a specific referral
// src/actions/referralActions.js - updated fetchEligibilityResults action

// New action to fetch eligibility results for a specific referral
export const fetchEligibilityResults = (referralId, bonusId) => async (dispatch, getState) => {
    const { bonuses } = getState().referrals;

    try {
      const client = getApolloClient();
      if (referralId) {
        dispatch({ type: REFERRAL_ACTIONS.FETCH_ELIGIBILITY_RESULTS_REQUEST });
        const { data } = await client.query({
          query: gql(queryReferralEligibilityResults),
          variables: {
            id: referralId,
          },
          fetchPolicy: 'network-only',
        });
        
        // Format the data for storage
        const referralData = data.getReferral;
        const eligibilityResults = referralData?.eligibilityResults 
          ? JSON.parse(referralData.eligibilityResults) 
          : [];
        
        // Include the full referral data to store in the bonus object
        const enrichedReferral = {
          id: referralData.id,
          userId: referralData.userId,
          user: referralData.user || {},
          eligibilityResults: referralData.eligibilityResults
        };
        
        dispatch({
          type: REFERRAL_ACTIONS.FETCH_ELIGIBILITY_RESULTS_SUCCESS,
          payload: {
            referralId,
            bonusId,
            referral: enrichedReferral
          }
        });
        
        return { eligibilityResults, referralData };
      } else if (bonusId) {
        dispatch({ type: REFERRAL_ACTIONS.UPDATE_REFERRAL_REQUEST });
        const bonusToUpdate = bonuses.find(bonus => bonus.id === bonusId);
        const { data } = await client.query({
          query: gql(queryBonusUserDetails),
          variables: {
            id: bonusToUpdate.userId,
          },
        });
        const userData = data.getUser;
        const updatedBonus = {
          ...bonusToUpdate,
          user: {...bonusToUpdate.user,...userData},
          referral: {...bonusToUpdate.referral, user: userData},
        };
        dispatch({
          type: REFERRAL_ACTIONS.UPDATE_BONUS_SUCCESS,
          payload: updatedBonus
        });
        return updatedBonus;
      }
    } catch (error) {
      console.error('Error fetching eligibility results:', error);
      if (referralId) {
        dispatch({
          type: REFERRAL_ACTIONS.FETCH_ELIGIBILITY_RESULTS_FAILURE,
          payload: error.message
        });
      } else if (bonusId) {
        dispatch({
          type: REFERRAL_ACTIONS.UPDATE_BONUS_FAILURE,
          payload: error.message
        });
      }
    }
  };

export const revalidateBonus = (bonusId) => async (dispatch, getState) => {
    dispatch({ type: REFERRAL_ACTIONS.REVALIDATE_BONUS_REQUEST, payload: { bonusId } });
    
    try {
      const client = getApolloClient();
      const { bonuses } = getState().referrals;
      const { currentUser } = getState().user;
      
      const bonusToRevalidate = bonuses.find(bonus => bonus.id === bonusId);
      
      if (!bonusToRevalidate) {
        throw new Error(`Bonus with ID ${bonusId} not found`);
      }
      
      if (bonusToRevalidate.referralId) {
        const { data } = await client.query({
          query: gql(queryReferralEligibilityResults),
          variables: {
            id: bonusToRevalidate.referralId,
          },
          fetchPolicy: 'network-only',
        });
        
        const eligibilityResults = JSON.parse(
          data?.getReferral?.eligibilityResults ?? '[]'
        );
        
        const logEntry = {
          date: dayjs().toISOString(),
          changeLog: `${currentUser.emailAddress} requested revalidation of the bonus on payment "${bonusToRevalidate.payment}"`,
        };
        
        eligibilityResults.push(logEntry);
        
        const updateInput = {
          input: {
            id: bonusToRevalidate.referralId,
            bonusStatus: 'revalidate',
            eligibilityResults: JSON.stringify(eligibilityResults),
          },
        };
        
        await client.mutate({
          mutation: gql(updateReferralStatus),
          variables: updateInput,
        });
        
        const updatedEligibilityResponse = await client.query({
          query: gql(queryReferralEligibilityResults),
          variables: {
            id: bonusToRevalidate.referralId,
          },
          fetchPolicy: 'network-only',
        });
        
        const updatedReferralData = updatedEligibilityResponse.data.getReferral;
        
        dispatch({
          type: REFERRAL_ACTIONS.REVALIDATE_BONUS_SUCCESS,
          payload: {
            bonusId,
            referralId: bonusToRevalidate.referralId,
            referral: updatedReferralData
          }
        });
        
        
        return updatedReferralData;
      } else {
        const updatedBonus = {
          ...bonusToRevalidate,
          bonusStatus: 'pending',
        };
        
        dispatch({
          type: REFERRAL_ACTIONS.REVALIDATE_BONUS_SUCCESS,
          payload: {
            bonusId,
            updatedBonus
          }
        });
                
        return updatedBonus;
      }
    } catch (error) {
      console.error('Revalidation error:', error);
      dispatch({ 
        type: REFERRAL_ACTIONS.REVALIDATE_BONUS_FAILURE, 
        payload: { bonusId, error: error.message }
      });
      
      return null;
    }
  };

export const createBonus = (bonusInput) => async (dispatch, getState) => {
  dispatch({ type: REFERRAL_ACTIONS.CREATE_BONUS_REQUEST });
  
  
  try {
    const client = getApolloClient();

    const { data } = await client.mutate({
      mutation: gql(createBonusMutation),
      variables: bonusInput,
    });

    const newBonusResponse = data.createBonus;

    dispatch({
      type: REFERRAL_ACTIONS.CREATE_BONUS_SUCCESS,
      payload: newBonusResponse
    });
    return newBonusResponse;
  } catch (error) {
    dispatch({ 
      type: REFERRAL_ACTIONS.CREATE_BONUS_FAILURE, 
      payload: error.message 
    });
  }
};

export const updateReferral = (input) => async (dispatch, getState) => {
  dispatch({ type: REFERRAL_ACTIONS.UPDATE_REFERRAL_REQUEST });
  
  try {
    const client = getApolloClient();
    const referralId = input.input.id;
    
    const referralResponse = await client.mutate({
      mutation: gql(updateReferralStatus),
      variables: {
        input: input.input,
      },
    });
    
    const updatedReferral = referralResponse.data.updateReferral;
    
    dispatch({
      type: REFERRAL_ACTIONS.UPDATE_REFERRAL_SUCCESS,
      payload: updatedReferral
    });
    
    return updatedReferral;
  } catch (error) {
    dispatch({ 
      type: REFERRAL_ACTIONS.UPDATE_REFERRAL_FAILURE, 
      payload: error.message 
    });
  }
};

export const updateBonusStatus = (updatedBonusInput, referralId, logEntries, argsEligibilityResults = null) => async (dispatch, getState) => {
  try {
    const client = getApolloClient();
    const { bonuses } = getState().referrals;
    const { id: bonusId, bonusStatus: newStatus } = updatedBonusInput;

    const bonusToUpdate = bonuses.find(bonus => bonus.id === bonusId);
    
    if (!bonusToUpdate) {
      throw new Error(`Bonus with ID ${bonusId} not found`);
    }
    const paidDate = bonusToUpdate.bonusStatus !== updatedBonusInput.bonusStatus && updatedBonusInput.bonusStatus
      ? bonusToUpdate.paidDate
      : updatedBonusInput.bonusStatus === 'paid' ? new Date().toISOString() : updatedBonusInput.paidDate || bonusToUpdate.paidDate;

    const modifiedBonusInput = {
      input: {...updatedBonusInput, paidDate},
    };
    const { data } = await client.mutate({
      mutation: gql(updateBonusAction),
      variables: modifiedBonusInput,
    });
    const updatedBonusResponse = data.updateBonus;
    let updatedBonus;
    let currentEligibilityResults = argsEligibilityResults;
    if (referralId) {
      if (!argsEligibilityResults) {
        const { data } = await client.query({
          query: gql(queryReferralEligibilityResults),
          variables: {
            id: referralId,
          },
          fetchPolicy: 'network-only',
        });
        
        currentEligibilityResults = parse(
          data?.getReferral?.eligibilityResults
        ) || [];
      }

      const newEligibilityResults = [...currentEligibilityResults, ...logEntries];
    
      
      const bonusesForReferral = bonuses.filter(b => b.referralId === referralId);
      const total = bonusesForReferral.length;
      let totalEarned = 0;
      let totalPaid = 0;
      let totalPending = 0;
      let totalNeedsReview = 0;
      
      for (const bonus of bonusesForReferral) {
        const status = bonus.id === bonusId ? newStatus : bonus.bonusStatus;
        
        if (status === 'paid' || status === 'earned') {
          totalEarned++;
        }
        if (status === 'paid') {
          totalPaid++;
        }
        if (status === 'pending') {
          totalPending++;
        }
        if (status === 'needsReview') {
          totalNeedsReview++;
        }
      }
      
      let referralBonusStatus;
      if (total === totalPaid) {
        referralBonusStatus = 'paid';
      } else if (total === totalEarned) {
        referralBonusStatus = 'earned';
      } else if (totalNeedsReview > 0) {
        referralBonusStatus = 'needsReview';
      } else if (totalPending > 0) {
        referralBonusStatus = 'pending';
      } else {
        referralBonusStatus = 'ineligible';
      }
      
      const updateInput = {
        input: {
          id: referralId,
          bonusStatus: referralBonusStatus,
          eligibilityResults: JSON.stringify(newEligibilityResults),
        },
      };
      
      
      const updatedReferral = await dispatch(updateReferral(updateInput));
      updatedBonus = {
        ...bonusToUpdate,
        ...updatedBonusResponse,
        referral: {...bonusToUpdate.referral,...updatedReferral},
      };
    } else {
      updatedBonus = {
        ...bonusToUpdate,
        ...updatedBonusResponse,
      };
    }
    
    dispatch({
      type: REFERRAL_ACTIONS.UPDATE_BONUS_SUCCESS,
      payload: updatedBonus
    });
    return updatedBonus;
  } catch (error) {
    dispatch({ 
      type: REFERRAL_ACTIONS.UPDATE_BONUS_FAILURE, 
      payload: error.message 
    });
  }
};

// Filter actions
export const setFilteredStatuses = (statuses) => ({
  type: REFERRAL_ACTIONS.SET_FILTERED_STATUSES,
  payload: statuses
});

export const setFilteredDates = (dates) => ({
  type: REFERRAL_ACTIONS.SET_FILTERED_DATES,
  payload: dates
});

export const setFilteredUserGroups = (userGroups) => ({
  type: REFERRAL_ACTIONS.SET_FILTERED_USER_GROUPS,
  payload: userGroups
});

export const setFilteredReferralId = (referralId) => ({
  type: REFERRAL_ACTIONS.SET_FILTERED_REFERRAL_ID,
  payload: referralId
});

export const setSearchQuery = (query) => ({
  type: REFERRAL_ACTIONS.SET_SEARCH_QUERY,
  payload: query
});

export const setExportData = (data) => ({
  type: REFERRAL_ACTIONS.SET_EXPORT_DATA,
  payload: data
});

export const fetchReferrals = createAction(FETCH_REFERRALS);
export const selectReferral = createAction(SET_CURRENT_REFERRAL);
export const updateReferralsNotHired = createAction(UPDATE_REFERRALS_NOT_HIRED);
