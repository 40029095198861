import gql from 'graphql-tag';
import _, { get } from 'lodash';
import { compose, graphql } from 'react-apollo';
import {
	QueryWebNotificationsDropdown,
	updateUser,
} from '../../graphql/custom/users';

export const withGetUserNotifications = (Component) => {
	return compose(
		graphql(gql(QueryWebNotificationsDropdown), {
			options: (props) => ({
				context: {
					headers: {
						'x-frame-options': 'deny',
					},
				},
				variables: {
					userId: props.currentUser.id,
					dateCreated: props.currentUser.lastNotificationCheck,
					nextToken: get(props, 'nextTokenNotifications', null),
				},
				fetchPolicy: 'network-only',
			}),
			props(response, previous) {
				const userNotifications = get(
					response,
					['data', 'queryWebNotificationsByUserIdDateCreatedIndex', 'items'],
					get(previous, 'userNotifications', [])
				);
				const nextTokenNotifications = get(
					response,
					[
						'data',
						'queryWebNotificationsByUserIdDateCreatedIndex',
						'nextTokenNotifications',
					],
					null
				);

				return {
					currentUser: response.ownProps.currentUser,
					userNotifications,
					nextTokenNotifications,
				};
			},
		}),
		graphql(gql(updateUser), {
			props: (props) => ({
				onUpdate(input) {
					props.mutate({
						variables: input,
						fetchPolicy: 'no-cache',
					});
				},
			}),
		})
	)(Component);
};
