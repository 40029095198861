let apolloClient = null;

export const setApolloClient = (client) => {
  apolloClient = client;
};

export const getApolloClient = () => {
  if (!apolloClient) {
    throw new Error('Apollo client is not set yet.');
  }
  return apolloClient;
};