import 'antd/dist/antd.less';
import Cookies from 'js-cookie';
import get from 'lodash/get';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Component } from 'react';
import { withApollo } from 'react-apollo';
import { BrowserRouter as Router } from 'react-router-dom';
import { graphql, logout, parse } from 'src/_shared/services/utils.js';
import { RouterContext } from './_shared/contexts';
import { dashboardActions, userActions } from './actions.js';
import AppRouter from './app-router.component';
import { configureApp } from './app.config';
import { store, persistor, injectClient } from './store';
import './style.scss';
import ThemeProvider from './theme-provider.jsx';
import { RnRAPIServiceProvider } from './rewardsAndRecognitionHelper/service/RnRAPI.service.jsx';
import { RnRCommonServiceProvider } from './RnRCommonComponent/service/RnRCommon.service.jsx';
import { setApolloClient } from 'src/clientService';

const getCachedJwt = () => {
  try {
    const jwt = Cookies.get('jwt');
    return jwt;
  } catch (error) {
    console.log(error);
  }
};
// Run configuration scripts
configureApp();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      auth: getCachedJwt(),
    };
    this.onAuthentication = this.onAuthentication.bind(this);
  }

  componentDidMount() {
    // Configure store with the Apollo client
    injectClient(this.props.client);
    setApolloClient(this.props.client);
    this.setupStoreSubscription();
  }
  
  setupStoreSubscription = () => {
    if (!store) return;
    
    let currentDisplayAs;
    let isFetchingTranslations = false;
    
    store.subscribe(async () => {
      const state = store.getState();
      let languageCode = get(state, 'user.currentUser.languageCode');
      const currentAllMultiLingualDataCode = get(
        state,
        'dashboard.allMultiLingualData[0].languageCode'
      );
      languageCode ||= 'US';

      if (
        languageCode !== currentAllMultiLingualDataCode &&
        !isFetchingTranslations
      ) {
        isFetchingTranslations = true;

        try {
          if (languageCode === 'US') {
            store.dispatch(
              dashboardActions.createSetMultiLingualData([
                { languageCode: 'US' },
              ])
            );
          } else {
            const getTranslationsByLanguageCode = async (token = null) => {
              const response = await graphql({
                input: {
                  languageCode,
                  nextToken: token,
                },
                query: 'queryMultiLingualByLanguageCodeIndex',
              });
              const items = get(response, 'items', []);
              const nextToken = get(response, 'nextToken');
              if (nextToken) {
                const moreTranslations =
                  await getTranslationsByLanguageCode(nextToken);
                return [...items, ...moreTranslations];
              }

              return items;
            };

            const allTranslationsByLanguageCode =
              await getTranslationsByLanguageCode();
            store.dispatch(
              dashboardActions.createSetMultiLingualData(
                allTranslationsByLanguageCode
              )
            );
          }
        } catch (error) {
          console.error('Failed to fetch translations:', error);
        } finally {
          isFetchingTranslations = false;
        }
      }

      // Set displayAs based on user's role during initial login
      // Skip this if it was a failed login attempt
      if (get(state, 'user.currentUser')) {
        const {
          user: {
            currentUser: { role },
          },
        } = store.getState();

        if (currentDisplayAs !== role) {
          currentDisplayAs = role;
          store.dispatch(userActions.updateDisplayAs(role));
        }
      }
    });
  };

  onAuthentication = (authToken, currentUser) => {
    try {
      Cookies.set('jwt', authToken, {
        secure: process.env.REACT_APP_INSECURE_COOKIES !== 'true',
      });
      this.setState({
        auth: true,
      });
    } catch (error) {
      console.log(error);
    }
  };

  render() {
    if (!store || !persistor) {
      return <div>Loading...</div>;
    }
    
    let jwt = null;
    let providerValue = null;

    try {
      let currentUser = store.getState();
      currentUser = get(currentUser, 'user.currentUser');
      const supportAdmin = parse(currentUser?.admin);
      const supportAdminPermissions = get(supportAdmin, 'permissions') === '*';
      const platformConfig = currentUser?.company?.platformConfig
        ? JSON.parse(currentUser.company.platformConfig)
        : { primaryPlatform: 'referrals', filterMode: 'exclusionary', filterList: []};
      providerValue = {
        auth: getCachedJwt(),
        onAuthentication: this.onAuthentication,
        currentUser,
        client: this.props.client,
        handleSignOut: this.props.handleSignOut,
        primaryPlatform: platformConfig.primaryPlatform,
      };
      const userSignupSettings = parse(
        currentUser?.company?.userSignupSettings || {}
      );
      const accountClaim = currentUser?.accountClaim;
      const userHasInactiveAccountClaim =
        userSignupSettings?.auth?.requireAccountClaimToAuthorize &&
        !accountClaim?.active;
      const { pathname } = window.location;
      if (
        currentUser?.id &&
        userHasInactiveAccountClaim &&
        !supportAdminPermissions &&
        pathname !== '/login' &&
        pathname !== '/logout' &&
        pathname !== '/'
      ) {
        logout();
      }
    } catch (error) {
      console.log(error);
    }

    return (
      <Provider store={store}>
			  <PersistGate loading={null} persistor={persistor}>
          <RnRAPIServiceProvider>
            <RnRCommonServiceProvider>
              <ThemeProvider>
                <RouterContext.Provider value={providerValue}>
                  <Router>
                    <AppRouter
                      auth={this.state.auth}
                      secrets={get(this.props, 'secrets')}
                    />
                  </Router>
                </RouterContext.Provider>
              </ThemeProvider>
            </RnRCommonServiceProvider>
          </RnRAPIServiceProvider>
        </PersistGate>
      </Provider>
    );
  }
}

export default withApollo(App);