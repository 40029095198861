import { useState, useEffect, useCallback, useMemo } from 'react';
import {
  Button,
  DatePicker,
  message,
  Select,
  Tooltip
} from 'antd';
import _, { get } from 'lodash';
import ReactGA from 'react-ga';
import {
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { configMode } from 'src/_shared/api/';
import Spinner from 'src/_shared/components/spinner/SpinnerComponent.jsx';
import { USER_ROLES } from 'src/_shared/constants/index.js';
import {
  getISODateRangeUTC,
  lambda,
  ml,
  searchOpenSearchNetwork,
  getSetErrorImageURL,
} from 'src/_shared/services/utils.js';
import fileIcon from '../_shared/assets/erin_lightgray.png';
import { ExportSelector } from '../_shared/components/export-selector/ExportSelector.jsx';
import {
  BonusTable,
  ColorCard,
  SearchComponent,
} from '../_shared/components/index.js';
import { AddBonusModal } from './bonus-modal';
import { FilterByStatus } from './bonus-report-components/index.js';

const BonusReportComponent = (props) => {
  const { 
    currentUser, 
    allMultiLingualData,
    client,
    location,
    bonuses = [],
    bonusCounts,
    totalBonusCount,
    loading,
    isCardDataSet,
    filteredStatuses,
    filteredDates,
    filteredReferralId: reduxFilteredReferralId,
    filteredUserGroups,
    searchQueryBonuses,
    exportTableData,
    fetchBonuses,
    onUpdateReferral,
    onCreateBonus,
    setFilteredStatuses,
    setFilteredDates,
    setFilteredUserGroups,
    setFilteredReferralId,
    setSearchQuery,
    setExportData
  } = props;
  
  const [visible, setVisible] = useState(false);
  const [searchedJobs, setSearchedJobs] = useState([]);
  const [userGroupOptions, setUserGroupOptions] = useState([]);
  const [errorImageURL, setErrorImageURL] = useState('');
  const [searchQueryJobs, setSearchQueryJobs] = useState('');
  const [jobsTimer, setJobsTimer] = useState(null);
  
  const company = useMemo(() => currentUser?.company, [currentUser]);
  const theme = useMemo(() => JSON.parse(company?.theme ?? '{}'), [company]);
  const userGroups = useMemo(() => company?.userGroups ?? [], [company]);
  const managerBonuses = useMemo(() => {
    return company?.disableManagerPermissionsByType
      ? JSON.parse(company.disableManagerPermissionsByType).managerBonuses
      : 'hidden';
  }, [company]);
  
  const filteredReferralId = useMemo(() => {
    return reduxFilteredReferralId || get(location, 'state.targetReferral');
  }, [reduxFilteredReferralId, location]);

  const filterOn = useMemo(() => {
    return filteredUserGroups?.length > 0 ||
      filteredStatuses?.length > 0 ||
      filteredDates?.length > 0 ||
      filteredReferralId ||
      searchQueryBonuses?.length > 0;
  }, [filteredUserGroups, filteredStatuses, filteredDates, filteredReferralId, searchQueryBonuses]);

  useEffect(() => {
    const init = async () => {
      
      const host = window.location.hostname;
      if (host === 'referrals.aus.com') {
        ReactGA.initialize('UA-128630809-2');
        ReactGA.pageview(window.location.pathname + window.location.search);
      } else if (host === 'app.erinapp.com') {
        ReactGA.initialize('UA-128630809-3');
        ReactGA.pageview(window.location.pathname + window.location.search);
      }
      
      const sortedUserGroups = _.sortBy(userGroups, ['name']);
      const options = sortedUserGroups.map((userGroup) => (
        <Select.Option key={userGroup.id} value={userGroup.id}>
          {userGroup.name}
        </Select.Option>
      ));
      setUserGroupOptions(options);
      
      // Fetch initial bonuses
      fetchInitialBonuses();
      setQueryJobsToState();
      
      // Set error image if available
      try {
        const errorImg = await getSetErrorImageURL(currentUser?.company?.errorImage?.key);
        if (errorImg) {
          setErrorImageURL(errorImg);
        }
      } catch (error) {
        console.error("Error setting error image:", error);
      }
    };
    
    init();
  }, []);
  
  const sortByAlph = useCallback((a, b) => {
    if (a > b) return 1;
    if (a < b) return -1;
    return 0;
  }, []);

  const fetchFilteredBonuses = useCallback(() => {
    const parameters = createSearchParameters();
    fetchBonuses(parameters);
  }, [
    company, 
    filteredDates, 
    filteredReferralId, 
    filteredStatuses, 
    filteredUserGroups, 
    searchQueryBonuses, 
    fetchBonuses
  ]);
  
  // When filter changes, update bonuses
  useEffect(() => {
    fetchFilteredBonuses();
  }, [filteredUserGroups, filteredStatuses, filteredDates, filteredReferralId, searchQueryBonuses]);
  
  const fetchInitialBonuses = useCallback(() => {
    const parameters = createSearchParameters();
    fetchBonuses(parameters);
  }, [company, fetchBonuses]);
  
  const createSearchParameters = useCallback(() => {
    return {
      query: searchQueryBonuses,
      size: 300,
      from: 0,
      role: get(currentUser, 'role'),
      currentUser: get(currentUser, 'id'),
      filters: {
        companies: [get(company, 'id')],
        dateEarned: filteredDates,
        referralId: filteredReferralId,
        statuses: filteredStatuses,
        userGroups: filteredUserGroups,
      },
    };
  }, [
    searchQueryBonuses, 
    currentUser, 
    company, 
    filteredDates, 
    filteredReferralId, 
    filteredStatuses, 
    filteredUserGroups
  ]);

  const getReport = useCallback(() => {
    const endpoint = 'export-bonus-data';
    const { companyId, cognitoId } = currentUser;
    message.success(`Export processing. You will receive an email when ready`);
    lambda({
      endpoint,
      variables: {
        companyId,
        cognitoId,
        configMode,
      },
    });
  }, [currentUser]);

  const setQueryJobsToState = useCallback(async (query = '', timeout = 0) => {
    const {
      filteredDepartments = [],
      filteredBonuses = [],
      filteredDistance = 0,
      filterOpenStatus = 'open',
      lat,
      lng,
      filteredSubCompanies = [],
      filteredJobLevels = [],
    } = {};

    const filterCompanies = get({}, 'filteredCompanies', []).map(
      (company) => get(company, 'id')
    );

    const extendedCompaniesData = get(
      currentUser,
      'extendedCompaniesData',
      []
    );

    const extendedCompanies = extendedCompaniesData.filter(
      (company) => get(company, 'disableExtendedUserJobsView') !== true
    );
    const isExtendedUser = get(currentUser, 'role') === 'extendedUser';

    const comps =
      extendedCompanies && extendedCompanies.length > 0 && isExtendedUser
        ? extendedCompanies
        : [get(currentUser, 'company')];

    const companies = comps.map((company) => get(company, 'id'));
    const filteredCompanies =
      filterCompanies.length > 0 ? filterCompanies : companies;

    const unit =
      get(currentUser, 'userGroup.measurement', 'imperial') === 'metric'
        ? 'km'
        : 'mi';
        
    clearTimeout(jobsTimer);
    setSearchQueryJobs(query);
    
    const timer = setTimeout(async () => {
      const parameters = {
        query,
        size: 300,
        from: 0,
        coordinates: [{ lat, lng }],
        role: get(currentUser, 'role'),
        currentUser: get(currentUser, 'id'),
        filters: {
          bonuses: filteredBonuses,
          companies: filteredCompanies,
          departments: filteredDepartments,
          distance: filteredDistance,
          status: filterOpenStatus,
          subCompanies: filteredSubCompanies,
          jobLevels: filteredJobLevels,
        },
        unit,
      };
      
      const response = await searchOpenSearchNetwork(parameters, 'erin-jobs');
      if (response && response.query === query) {
        setSearchedJobs(get(response, 'data', []));
      }
    }, timeout);
    
    setJobsTimer(timer);
  }, [currentUser, jobsTimer]);

  const handleDateRangeFilter = useCallback((dates = []) => {
    let formattedDates = {};
    if (dates && dates.length > 0) {
      const [from, to] = getISODateRangeUTC(dates);
      formattedDates = { from, to };
    }
    setFilteredDates(formattedDates);
  }, [setFilteredDates]);

  const handleFilterUserGroup = useCallback((userGroup) => {
    const newUserGroups = [...filteredUserGroups, userGroup];
    setFilteredUserGroups(newUserGroups);
  }, [filteredUserGroups, setFilteredUserGroups]);

  const handleRemoveUserGroupFilter = useCallback((userGroup) => {
    const index = filteredUserGroups.indexOf(userGroup);
    if (index !== -1) {
      const newUserGroups = [...filteredUserGroups];
      newUserGroups.splice(index, 1);
      setFilteredUserGroups(newUserGroups);
    }
  }, [filteredUserGroups, setFilteredUserGroups]);

  const handleStatusFilter = useCallback((value) => {
    let statusValue = value;
    if (statusValue === 'eligible') statusValue = 'earned';
    const newStatuses = [...filteredStatuses, statusValue];
    setFilteredStatuses(newStatuses);
  }, [filteredStatuses, setFilteredStatuses]);

  const handleRemoveStatusFilter = useCallback((value) => {
    let statusValue = value;
    if (statusValue === 'eligible') statusValue = 'earned';
    const index = filteredStatuses.indexOf(statusValue);
    if (index !== -1) {
      const newStatuses = [...filteredStatuses];
      newStatuses.splice(index, 1);
      setFilteredStatuses(newStatuses);
    }
  }, [filteredStatuses, setFilteredStatuses]);

  const handleRemoveReferralIdFilter = useCallback(() => {
    if (get(location, 'state.targetReferral')) {
      location.state.targetReferral = null;
    }
    setFilteredReferralId(null);
  }, [location, setFilteredReferralId]);

  const filterByReferralId = useCallback((id) => {
    setFilteredReferralId(id);
  }, [setFilteredReferralId]);

  const showModal = useCallback(() => {
    setVisible(true);
  }, []);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, []);

  const renderArrowIcon = useCallback(() => {
    return (
      <div>
        <i className="icon-sort-down" style={{ color: 'var(--oslo-gray)', fontSize: '10px' }} />
      </div>
    );
  }, []);

  if (!get(currentUser, 'company')) return <Spinner />;
  
  const { RangePicker } = DatePicker;
  const isManagerPermissionDisabled = Boolean(
    managerBonuses !== 'edit' && currentUser?.role === USER_ROLES.MANAGER
  );
  const whiteLabel = get(company, 'whiteLabel');

  return (
    <main>
      <div className="page-title">
        <h2 className="page-heading">
          {ml('All Bonuses', currentUser, allMultiLingualData)}
        </h2>
        {!isManagerPermissionDisabled && (
          <ul className="info-action">
            <li>
              <Button
                type="link"
                className="add-btn btn-lg"
                onClick={showModal}
              >
                <span className="icon-circle">
                  <i className="icon-plus" />
                </span>
                {ml('Add Bonus', currentUser, allMultiLingualData)}
              </Button>
            </li>
          </ul>
        )}
      </div>
      
      <div className="color-card-grid">
        <ColorCard
          bgColor="var(--bright-turquoise)"
          count={
            bonuses &&
            Number.parseInt(bonusCounts.total).toLocaleString('en-US')
          }
          description={ml(
            'Total number of bonuses that have been created.',
            currentUser,
            allMultiLingualData
          )}
          isCardDataSet={isCardDataSet}
          title={ml(
            'Total Bonuses',
            currentUser,
            allMultiLingualData
          )}
        />
        <ColorCard
          bgColor="var(--tan-hide)"
          count={
            bonusCounts.pending &&
            Number.parseInt(bonusCounts.pending).toLocaleString('en-US')
          }
          description={ml(
            'Total number of bonuses that are currently in a waiting period and not yet eligible to be paid.',
            currentUser,
            allMultiLingualData
          )}
          isCardDataSet={isCardDataSet}
          title={ml('Pending', currentUser, allMultiLingualData)}
        />
        <ColorCard
          bgColor="var(--fern)"
          count={
            bonusCounts.earned &&
            Number.parseInt(bonusCounts.earned).toLocaleString('en-US')
          }
          description={
            <>
              {ml(
                'Total number of bonuses that are currently eligible to be paid',
                currentUser,
                allMultiLingualData
              )}
              <span className="mr-1" />
              <Tooltip
                title={ml(
                  `All eligible checks and waiting periods are complete`,
                  currentUser,
                  allMultiLingualData
                )}
              >
                <InfoCircleOutlined />
              </Tooltip>
            </>
          }
          isCardDataSet={isCardDataSet}
          title={ml('Eligible', currentUser, allMultiLingualData)}
        />
        <ColorCard
          bgColor="var(--forest-green)"
          count={
            bonusCounts.paid &&
            Number.parseInt(bonusCounts.paid).toLocaleString('en-US')
          }
          description={ml(
            'Total number of bonuses that have been paid.',
            currentUser,
            allMultiLingualData
          )}
          isCardDataSet={isCardDataSet}
          title={ml('Paid', currentUser, allMultiLingualData)}
        />
        <ColorCard
          bgColor="var(--sunset-orange)"
          count={
            bonusCounts.ineligible &&
            Number.parseInt(bonusCounts.ineligible).toLocaleString('en-US')
          }
          description={ml(
            'Total number of bonuses that are currently ineligible for any reason.',
            currentUser,
            allMultiLingualData
          )}
          isCardDataSet={isCardDataSet}
          title={ml(
            'Ineligible',
            currentUser,
            allMultiLingualData
          )}
        />
        <ColorCard
          bgColor="var(--mellow-yellow)"
          count={
            bonusCounts.needsReview &&
            Number.parseInt(bonusCounts.needsReview).toLocaleString('en-US')
          }
          description={ml(
            'Total number of bonuses that are currently flagged for manual review.',
            currentUser,
            allMultiLingualData
          )}
          isCardDataSet={isCardDataSet}
          title={ml(
            'Needs Review',
            currentUser,
            allMultiLingualData
          )}
        />
      </div>
      
      <div className="page-top-filter">
        <div className="filter-wrap">
          <SearchComponent
            searchQuery={searchQueryBonuses}
            setQueryToState={(query) => setSearchQuery(query)}
            placeholder={ml('Search', currentUser, allMultiLingualData)}
          />

          <Select
            showArrow
            mode="multiple"
            placeholder={ml('User Group', currentUser, allMultiLingualData)}
            maxTagCount={1}
            maxTagTextLength={15}
            suffixIcon={renderArrowIcon()}
            onSelect={(userGroup) => handleFilterUserGroup(userGroup)}
            onDeselect={(userGroup) => handleRemoveUserGroupFilter(userGroup)}
          >
            {userGroupOptions}
          </Select>
          
          <FilterByStatus
            allMultiLingualData={allMultiLingualData}
            currentUser={currentUser}
            filteredStatuses={filteredStatuses}
            handleStatusFilter={handleStatusFilter}
            handleRemoveStatusFilter={handleRemoveStatusFilter}
          />
          
          <RangePicker
            className="ant-picker-320w-br"
            separator={<span>~</span>}
            format="MM-DD-YYYY"
            startdateText={ml('Start date', currentUser, allMultiLingualData)}
            enddateText={ml('End date', currentUser, allMultiLingualData)}
            onChange={(value) => {
              handleDateRangeFilter(value ?? []);
            }}
          />
          
          {filteredReferralId && (
            <Button
              type="link"
              className="danger"
              onClick={handleRemoveReferralIdFilter}
            >
              <CloseCircleOutlined />
              <span>
                {ml('Clear Filters', currentUser, allMultiLingualData)}
              </span>
            </Button>
          )}

          {filterOn && totalBonusCount > 300 && (
            <Tooltip
              title={ml(
                `Your filter includes over 300 results. Only the first 300 are displayed.`,
                currentUser,
                allMultiLingualData
              )}
              placement="right"
            >
              <ExclamationCircleOutlined style={{ marginLeft: 10 }} />
            </Tooltip>
          )}
        </div>
        
        <ExportSelector
          title={ml('Export', currentUser, allMultiLingualData)}
          items={[
            {
              key: 1,
              label: (
                <Tooltip
                  title={ml(
                    `You will receive an export via email of all data.`,
                    currentUser,
                    allMultiLingualData
                  )}
                  placement="left"
                >
                  {ml('Export All', currentUser, allMultiLingualData)}
                </Tooltip>
              ),
              onClick: getReport,
            },
            {
              key: 2,
              label: (
                <Tooltip
                  title={
                    totalBonusCount > 300
                      ? ml(
                          'Unavailable: More than 300 results. Please filter to enable.',
                          currentUser,
                          allMultiLingualData
                        )
                      : ml(
                          `Instantly download an export of the filtered results.`,
                          currentUser,
                          allMultiLingualData
                        )
                  }
                  placement="left"
                >
                  {ml('Export View', currentUser, allMultiLingualData)}
                </Tooltip>
              ),
              onClick: 'csv-export',
              data: exportTableData,
              disabled: totalBonusCount > 300,
              reportType: 'bonus',
              companyName: currentUser?.company?.name,
            },
          ]}
        />
      </div>
      
      <AddBonusModal
        currentUser={currentUser}
        theme={theme}
        open={visible}
        jobs={searchedJobs}
        allMultiLingualData={allMultiLingualData}
        handleClose={handleClose}
        client={client}
        onCreateBonus={onCreateBonus}
        fetchInitialBonuses={fetchInitialBonuses}
      />

      {bonuses.length > 0 && !loading ? (
        <div className="table-card">
          <BonusTable
            filterByReferralId={filterByReferralId}
            filteredReferralId={filteredReferralId}
            company={company}
            theme={theme}
            bonuses={bonuses}
            setExportData={setExportData}
            tieredBonusQuery={props.tieredBonusQuery}
            role={currentUser.role}
            sortByAlph={sortByAlph}
            rowKey={(record) => record.id}
            currentUser={currentUser}
            allMultiLingualData={allMultiLingualData}
            onUpdateReferral={onUpdateReferral}
            client={client}
          />
        </div>
      ) : loading ? (
        <Spinner company={company} />
      ) : (
        <div className="no-content">
          {whiteLabel && errorImageURL ? (
            <img
              src={errorImageURL}
              alt="error image"
              className="no-content-icon"
            />
          ) : (
            <img alt="erin-logo" className="no-content-icon" src={fileIcon} />
          )}
          {filterOn ? (
            <p className="no-content-text">
              {ml(
                'There are no bonuses that meet the search criteria.',
                currentUser,
                allMultiLingualData
              )}
            </p>
          ) : (
            <p className="no-content-text">
              {ml(
                'There are no bonuses. Bonuses are created automatically when a referral is marked as',
                currentUser,
                allMultiLingualData
              )}{' '}
              <strong>{ml('Hired', currentUser, allMultiLingualData)}</strong>
              .
            </p>
          )}
        </div>
      )}
    </main>
  );
};

export default BonusReportComponent;
