export const QueryWebNotificationsDropdown = `query QueryWebNotificationsDropdown(
    $userId: ID!
    $dateCreated: String
    $first: Int
    $after: String
  ) {
    queryWebNotificationsByUserIdDateCreatedIndex(
      userId: $userId
      dateCreated: $dateCreated
      first: $first
      after: $after
    ) {
          items {
              id
              userId
              status
              type
              dateCreated
              companyId
              referralId
              referral {
                  contactId
                  contact {
                      id
                      firstName
                      lastName
                  }
                  userId
              }
              referralRequestedStatus
              requestingUserId
              requestingUser {
                  id
                  firstName
                  lastName
              }
              message
              jobId
              job {
                  id
                  title
                  subCompanyId
                  subCompany {
                      name
                  }
              }
              matches
              contactId
              contact {
                  id
                  firstName
                  lastName
              }
          }
      nextToken
      }
  }
  `;
  