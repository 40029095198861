import { createStore, applyMiddleware, compose } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import localforage from 'localforage';
import thunk from 'redux-thunk';
import reducer from './reducer';

const persistConfig = {
  key: 'root',
  storage: localforage,
  stateReconciler: autoMergeLevel2,
  timeout: 30000,
};

const persistedReducer = persistReducer(persistConfig, reducer);

// Create the store without the client
export const store = createStore(
  persistedReducer,
  undefined,
  compose(applyMiddleware(thunk.withExtraArgument({ client: null })))
);

export const persistor = persistStore(store);

/**
 * Call this function once the Apollo client is available.
 * This will update the thunk extra argument.
 */
export const injectClient = (client) => {
  // Update the extra argument for future dispatched thunks
  // Note: This won't affect already dispatched actions.
  store.dispatch({ type: 'INJECT_CLIENT', client });
};
