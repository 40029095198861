import { REFERRAL_ACTIONS } from './referralActions.js';

const initialState = {
	bonuses: [],
	bonusCounts: {
	  earned: 0,
	  ineligible: 0,
	  needsReview: 0,
	  paid: 0,
	  pending: 0,
	  total: 0,
	},
	referrals: [],
	currentReferral: '',
	totalBonusCount: 0,
	loading: false,
	error: null,
	isCardDataSet: false,
	
	// Filters
	filteredStatuses: [],
	filteredDates: [],
	filteredUserGroups: [],
	filteredReferralId: null,
	searchQueryBonuses: '',
	
	// Export
	exportTableData: [],
	
	// Eligibility Results
	eligibilityResults: {},
	referringUserData: {},
	fetchingEligibility: false,
	eligibilityError: null,
	
	// Revalidation
	revalidatingBonuses: {}, // Track which bonuses are being revalidated
	revalidationError: null,
  };

export const referralReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFERRAL_ACTIONS.FETCH_BONUSES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
    case REFERRAL_ACTIONS.FETCH_BONUSES_SUCCESS:
      return {
        ...state,
        bonuses: action.payload.bonuses,
        totalBonusCount: action.payload.totalCount,
        loading: false,
        isCardDataSet: true,
        error: null,
      };
      
    case REFERRAL_ACTIONS.FETCH_BONUSES_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case REFERRAL_ACTIONS.FETCH_BONUS_COUNTS_SUCCESS:
      return {
        ...state,
        bonusCounts: action.payload,
      };
      
    case REFERRAL_ACTIONS.UPDATE_BONUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
    case REFERRAL_ACTIONS.UPDATE_BONUS_SUCCESS:
      return {
        ...state,
        loading: false,
        bonuses: state.bonuses.map(bonus => 
          bonus.id === action.payload.id 
            ? { ...bonus, ...action.payload } 
            : bonus
        ),
      };
      
    case REFERRAL_ACTIONS.UPDATE_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case REFERRAL_ACTIONS.CREATE_BONUS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
    case REFERRAL_ACTIONS.CREATE_BONUS_SUCCESS:
      return {
        ...state,
        loading: false,
        bonuses: [action.payload, ...state.bonuses],
      };
      
    case REFERRAL_ACTIONS.CREATE_BONUS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case REFERRAL_ACTIONS.UPDATE_REFERRAL_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
    case REFERRAL_ACTIONS.UPDATE_REFERRAL_SUCCESS:
      // Update eligibility results for the referral if they were fetched before
      const updatedEligibilityResults = { ...state.eligibilityResults };
      if (updatedEligibilityResults[action.payload.id]) {
        updatedEligibilityResults[action.payload.id] = JSON.parse(
          action.payload.eligibilityResults || '[]'
        );
      }
      
      return {
        ...state,
        loading: false,
        eligibilityResults: updatedEligibilityResults,
        bonuses: state.bonuses.map(bonus => 
          bonus.referralId === action.payload.id && bonus.bonusStatus !== action.payload.bonusStatus
            ? { ...bonus, bonusStatus: action.payload.bonusStatus }
            : bonus
        ),
      };
      
    case REFERRAL_ACTIONS.UPDATE_REFERRAL_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      

	case REFERRAL_ACTIONS.FETCH_ELIGIBILITY_RESULTS_REQUEST:
		return {
			...state,
			fetchingEligibility: true,
			eligibilityError: null,
		};
  
	case REFERRAL_ACTIONS.FETCH_ELIGIBILITY_RESULTS_SUCCESS:
		return {
			...state,
			fetchingEligibility: false,
			bonuses: state.bonuses.map(bonus => 
			bonus.id === action.payload.bonusId 
				? { 
					...bonus, 
					referral: {
					...bonus.referral,
					...action.payload.referral,
					id: bonus.referralId || action.payload.referralId,
					jobId: bonus.referral?.jobId,
					referralType: bonus.referral?.referralType,
					}
				} 
				: bonus
			),
			eligibilityResults: {
			...state.eligibilityResults,
			[action.payload.referralId]: JSON.parse(action.payload.referral.eligibilityResults || '[]')
			},
			referringUserData: {
			...state.referringUserData,
			[action.payload.referralId]: action.payload.referral.user || {}
			},
			eligibilityError: null,
		};
  
	case REFERRAL_ACTIONS.FETCH_ELIGIBILITY_RESULTS_FAILURE:
		return {
			...state,
			fetchingEligibility: false,
			eligibilityError: action.payload,
		};
      
	case REFERRAL_ACTIONS.REVALIDATE_BONUS_REQUEST:
		return {
		  ...state,
		  revalidatingBonuses: {
			...state.revalidatingBonuses,
			[action.payload.bonusId]: true
		  },
		  revalidationError: null,
		};
		
	  case REFERRAL_ACTIONS.REVALIDATE_BONUS_SUCCESS:
		if (action.payload.referral) {
		  const updatedBonuses = state.bonuses.map(bonus => 
			bonus.id === action.payload.bonusId 
			  ? { 
				  ...bonus, 
				  referral: {
					...bonus.referral,
					...action.payload.referral,
					eligibilityResults: action.payload.referral.eligibilityResults || bonus.referral?.eligibilityResults,
					id: bonus.referralId || action.payload.referralId,
					jobId: bonus.referral?.jobId,
					referralType: bonus.referral?.referralType,
				  }
				} 
			  : bonus
		  );
		  
		  return {
			...state,
			bonuses: updatedBonuses,
			revalidatingBonuses: {
			  ...state.revalidatingBonuses,
			  [action.payload.bonusId]: false
			},
			eligibilityResults: {
			  ...state.eligibilityResults,
			  [action.payload.referralId]: JSON.parse(action.payload.referral.eligibilityResults || '[]')
			},
			revalidationError: null,
		  };
		} else if (action.payload.updatedBonus) {
		  return {
			...state,
			bonuses: state.bonuses.map(bonus => 
			  bonus.id === action.payload.bonusId 
				? { ...bonus, ...action.payload.updatedBonus } 
				: bonus
			),
			revalidatingBonuses: {
			  ...state.revalidatingBonuses,
			  [action.payload.bonusId]: false
			},
			revalidationError: null,
		  };
		}
		return state;
		
	  case REFERRAL_ACTIONS.REVALIDATE_BONUS_FAILURE:
		return {
		  ...state,
		  revalidatingBonuses: {
			...state.revalidatingBonuses,
			[action.payload.bonusId]: false
		  },
		  revalidationError: action.payload.error,
		};
      
    case REFERRAL_ACTIONS.SET_FILTERED_STATUSES:
      return {
        ...state,
        filteredStatuses: action.payload,
      };
      
    case REFERRAL_ACTIONS.SET_FILTERED_DATES:
      return {
        ...state,
        filteredDates: action.payload,
      };
      
    case REFERRAL_ACTIONS.SET_FILTERED_USER_GROUPS:
      return {
        ...state,
        filteredUserGroups: action.payload,
      };
      
    case REFERRAL_ACTIONS.SET_FILTERED_REFERRAL_ID:
      return {
        ...state,
        filteredReferralId: action.payload,
      };
      
    case REFERRAL_ACTIONS.SET_SEARCH_QUERY:
      return {
        ...state,
        searchQueryBonuses: action.payload,
      };
      
    case REFERRAL_ACTIONS.SET_EXPORT_DATA:
      return {
        ...state,
        exportTableData: action.payload,
      };
      
    default:
      return state;
  }
};

export default referralReducer;